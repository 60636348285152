import TableRow from 'shared/components/table_row'
import { html } from 'lit-html'
import { modelWrapper } from 'shared/utilities/model_wrapper'
import { compact, truncate } from 'lodash'

export default class PhoneTableRow extends TableRow {
	get HTML() {
		return modelWrapper(this.model, this._innerHTML, {classes: {'table-row': true}})
	}

// PRIVATE
	
	_detailNote(attribute = 'note') {
		const value = truncate(this.model.attributes[attribute], {length: 100})
		return value || null
	}

	get _detailsHTML() {
		const compactDetails = compact(this._details)
		if (compactDetails.length == 0) return null
		return html`<div class="details">${compactDetails.join(' • ')}</div>`
	}

	get _headingHTML() {
		return html`
			<div class="heading">
				<div class="title">${this._name}</div>
				${this._headingLabel}
			</div>`
	}

	get _innerHTML() {
		return html`
			${this._avatar}
			<div class="body">
				${this._headingHTML}
				${this._detailsHTML}
			</div>
		`
	}
}