import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { round, sumBy } from 'lodash'
import { durationDisplay } from 'shared/utilities/duration'

export default class extends Controller {
  initialize() {
    if (!this._visible) this.element.remove()
  }

  connect() {
    this._autorunDisposer = autorun(() => this._updateValue())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

  _updateValue() {
  	this.element.classList.toggle('over', this._durationDifference > 0)
  	this.element.classList.toggle('under', this._durationDifference < 0)
    this.element.innerHTML = durationDisplay(Math.abs(this._durationDifference))
  }  

  get _date() {
    if (!this.data.has('dateIndex')) return undefined
    return moment(current.dateRange.padded_start_date).add(this.data.get('dateIndex'), 'day').format('YYYY-MM-DD')
  }

  @computed get _durationDifference() {
  	return this._timesheetsDuration - this._shiftsDuration
  }

  get _rowId() {
  	if (!this.data.has('rowId')) return undefined
  	return this.data.get('rowId') && parseInt(this.data.get('rowId')) || null
  }

  @computed get _shifts() {
    return shifts.cellCollectionLookup('filtered', this._rowId, this._date).filter(s => s.attributes.published)
  }

  @computed get _shiftsDuration() {
  	return sumBy(this._shifts, model => model.attributes['duration'])
  }

  @computed get _timesheets() {
    return timesheets.cellCollectionLookup('filtered', this._rowId, this._date)
  }

  @computed get _timesheetsDuration() {
    var collection = this._timesheets
    if (current.baseStore.modelName == "timesheet") {
      var filteredCollection = []
      for (var i = 0; i < collection.length; i++) {
        if (collection[i].attributes.time_off_id == null) {
          filteredCollection.push(collection[i]);
        }
      }
      collection = filteredCollection
    }
  	return sumBy(collection, model => model.attributes['duration'])
  }

  get _myTotals() {
    return current.parentAssignment && current.addonSetting('my_totals_enabled')
  }

  get _visible() {
    if (!current.isBaseModelName('timesheet') || !current.addonSetting('timesheet_comparison_enabled')) return false
    return current.isSupervisor || this._myTotals
  }
}