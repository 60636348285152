const conflictModelConcern = (superclass) => class extends superclass {  
	conflictingFilter(value){
		if (value == 'conflicting') {
			return this.attributes['conflict']
		} else {
			return true
		}
	}

	get _isConflictingFiltered() {
		return this.conflictingFilter(current.filterScope(`${this.store.modelName}_conflicting`))
	}
}

export default conflictModelConcern