import { Controller } from "stimulus"
import filestack from 'filestack-js'

export default class extends Controller {
  static targets = ['fileName', 'hiddenInput']

  initialize() {
   this.filestackClient = filestack.init(this.data.get('filestackApiKey'))
  }

  chooseFile() {
    this.filestackClient.pick().then(response => {
      this.handleFilestackResponse(response)
    })
  }

  handleFilestackResponse(response) {
  	const file = response.filesUploaded[0]
  	if (file) {
	  	this.hiddenInputTarget.value = file.url
      this.hiddenInputTarget.dispatchEvent(new Event('change'))
	  	this.fileNameTarget.innerHTML = file.filename
  	}
  }
}