import { Controller } from "stimulus"
import { last } from 'lodash'

export default class extends Controller {
  static targets = ['row']

  addRow() {
  	if (this.rowTargets.length < current.schedule.user_limits ) {
		last(this.rowTargets).after(this._rowHTML)
	}
  }

  removeRow(event) {
  	$(event.target).closest('.form-row').remove()
  }

  get _rowHTML() {
  	let clone = $(this.rowTarget).clone()
  	clone.find("input[type='text']").val('')
    clone.find(".select-list-wrapper").data('selected-values', false)
  	clone.find('.field_with_errors > *').each((index, element) => {
  		$(element).unwrap()
  	})
  	clone.find('.error-feedback').remove()
  	clone.find('.form-group > label').remove()
  	return clone[0]
  }
}