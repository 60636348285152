import PhoneTableRow from 'shared/components/phone_table_row'

export default class SwapPhoneTableRow extends PhoneTableRow {	
	get _avatar() {
		return this._assignmentAvatar(this.model.attributes.assignment_attributes)
	}

	get _details() {
		return [
			this._dateTimeFormat(this.model.attributes.start_at, this.model.attributes.end_at), 
			this.model.attributes.position_name, 
			this.model.attributes.location_name,
			this._detailNote('shift_note')
		]
	}

	get _headingLabel() {
		return this._labelFormat('status')
	}

	get _name() {
		return this._assignmentName(this.model.attributes.assignment_attributes)
	}
}