import { Controller } from "stimulus"
import "bootstrap-colorpicker"

export default class extends Controller {
  static targets = ['icon', 'input']

  initialize() {
  	$(this.inputTarget).colorpicker({
  		color: $(this.inputTarget).val() || '#7d8c9f',
  		fallbackColor: '#7d8c9f',
  		format: 'hex',
  		useAlpha: false
  	}).on('change', event => {
  		this.updateIcon()
  	})

  	this.updateIcon()
  }

  updateIcon() {
  	$(this.iconTarget).css('color', $(this.inputTarget).val())
  }

  showPicker() {
  	$(this.inputTarget).focus()
  }
}