import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['settingtimezone', 'usertimezone']
  initialize() {
  	this.updateClock()
  	setInterval(() => this.updateClock(), 1000)
  }

  updateClock() {
    if(this.settingtimezoneTarget.value == "true") {
      $("." + this.element.className + " .time").html(moment().utcOffset(this.usertimezoneTarget.value).format('h:mm') + moment().format('a'))
    } else {
      $("." + this.element.className + " .time").html(moment().format('h:mm') + moment().format('a'))
    }
  }
}