import { computed } from 'mobx'
import Model from 'shared/models/model'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import statusModelConcern from 'shared/models/concerns/status_model_concern'
import AssignmentTableRow from 'shared/components/assignment_table_row'
import AssignmentPhoneTableRow from 'shared/components/assignment_phone_table_row'

export default class Assignment extends statusModelConcern(filterModelConcern(Model)) {
	store = assignments

	@computed get isBaseFiltered() {
		return this.isCollectionsFiltered && this._variationFiltered
	}

	@computed get isFiltered() {
		return this.isBaseFiltered && this._isRoleFiltered && this._isStatusFiltered && this._isIntegrationFlagFiltered
	}

	get isDraggable() {
		return current.isManager
	}

	get phoneTableRow() {
		return new AssignmentPhoneTableRow(this).HTML
	}

	get tableRow() {
		return new AssignmentTableRow(this).HTML
	}

	integrationFlagFilter(value){
		if (value) {
			return this.attributes['integration_flag'] == value
		} else {
			return true
		}
	}

// PRIVATE

	get _variationFiltered() {
		return current.variationType == 'not_archived' ? !this.attributes['archived'] : this.attributes['archived']
	}

	get _isRoleFiltered() {
		let role = current.filterScope('assignment_role')
		return !role || this.attributes['role'] == role
	}

	get _isIntegrationFlagFiltered() {
		let integration_flag = current.filterScope('integration_flag')
		return !integration_flag || this.attributes['integration_flag'] == integration_flag
	}

// TABLE CELLS

	get _emailCell() {
		return this.attributes.email
	}

	get _employeeIdCell() {
		return this.attributes.employee_id
	}

	get _invitedAtCell() {
		return timestampCell(this.attributes.invited_at)
	}

	get _lastSessionAtCell() {
		return timestampCell(this.attributes.last_session_at)
	}

	get _locationsCell() {
		return this.attributes.location_names
	}

	get _nameCell() {
		return nameCell(this.attributes)
	}

	get _phoneCell() {
		return this.attributes.phone
	}

	get _positionsCell() {
		return this.attributes.position_names
	}

	get _roleCell() {
		return labelCell(this, 'role')
	}

	get _statusCell() {
		return labelCell(this, 'status')
	}

	get _wageCell() {
		return currencyCell(this.attributes.wage)
	}
}