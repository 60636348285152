import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { round, sumBy } from 'lodash'
import { durationDisplay } from 'shared/utilities/duration'
import { currencyDisplay } from 'shared/utilities/currency'

export default class extends Controller {
	static targets = ['cost', 'disabledWarning', 'duration', 'overtime', 'shiftCount']

  initialize() {
    if (this._visible) {
      if (!this._costVisible && this.hasCostTarget) this.costTarget.remove()
    } else {
      this.element.remove()
    }
  }

  connect() {
    this._autorunDisposer = autorun(() => this._updateTotals())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

  _updateTotals() {
    this._toggleDisabledWarning()
    this._updateShiftCount()
    var duration = this._duration
    var cost = this._cost
    if (current.baseStore.modelName == "timesheet") {
      var time_off_duration = this._durationTimeOff
      var time_off_cost = this._costTimeOff
      duration = duration + " (" + time_off_duration + ")"
    }
    if (current.dateRange.interval == 'month'){
      var start_date = moment(current.dateRange.start_date)
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      var total_txt = months[start_date.format('MM') - 1] + " Total : "
      duration = total_txt + duration
    }
    if ((current.baseStore.modelName == "timesheet" || current.baseStore.modelName == "shift") && (current.premium && current.overtime_enabled)) {
      var overtime_flag = this._overtimeFlag == 0
      if (!overtime_flag) {
        var regular_duration = this._regularDuration
        var regular_cost = this._regularCost
        var overtime_duration = this._overtimeDuration
        var overtime_cost = this._overtimeCost
        if (!current.isPhoneVariant) {
          var tooltip_txt = "Regular: " + regular_duration + " / " + regular_cost + "<br>Overtime: " + overtime_duration + " / " + overtime_cost
          this.overtimeTarget.attributes['data-original-title'].value = tooltip_txt
        } else {
          var overtime_txt = "OT: " + overtime_duration + " / " + overtime_cost + " Reg: " + regular_duration + " / " + regular_cost
          this.overtimeTarget.parentElement.parentElement.nextElementSibling.children[0].innerHTML = overtime_txt

        }
      }
      this.overtimeTarget.classList.toggle('d-none', overtime_flag)
    }
    if (this.hasCostTarget) this.costTarget.innerHTML = cost
    if (this.hasDurationTarget) this.durationTarget.innerHTML = duration
  }

  @computed get _collection() {
    var filteredCollection = []
    var collection = this._store.cellCollectionLookup(this._collectionName, this._rowId, this._date)
    if (current.dateRange.interval == 'month'){
      for (var i = 0; i < collection.length; i++) {
        if (moment(collection[i].date) >= moment(current.dateRange.start_date) && moment(collection[i].date) <= moment(current.dateRange.end_date)) {
          filteredCollection.push(collection[i]);
        }
      }
    } else {
      filteredCollection = collection
    }
    return filteredCollection
  }

  get _collectionName() {
    return this.data.get('collectionName') || 'filtered'
  }

  @computed get _timeOffCollection(){
    var filteredCollection = []
    var collection = this._collection
    for (var i = 0; i < collection.length; i++) {
      if (collection[i].attributes.time_off_id != null) {
        filteredCollection.push(collection[i]);
      }
    }
    return filteredCollection
  }

// PRIVATE

  @computed get _cost() {
    if ((current.premium && current.overtime_enabled) && (current.baseStore.modelName == "timesheet" || current.baseStore.modelName == "shift")) {
      return currencyDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["regular_cost"] + model.attributes["overtime_calculations"]["overtime_cost"]))
    } else {
      return currencyDisplay(sumBy(this._collection, model => model.attributes['cost']))
    }
  }

  @computed get _duration() {
	if ((current.premium && current.overtime_enabled) && (current.baseStore.modelName == "timesheet" || current.baseStore.modelName == "shift")) {
      return durationDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["regular_duration"] + model.attributes["overtime_calculations"]["overtime_duration"]))
    } else {
      return durationDisplay(sumBy(this._collection, model => model.attributes['duration']))
    }
  }

  @computed get _durationTimeOff() {
    return durationDisplay(sumBy(this._timeOffCollection, model => model.attributes['duration']))
  }

  @computed get _costTimeOff() {
    return currencyDisplay(sumBy(this._timeOffCollection, model => model.attributes['cost']))
  }

  @computed get _overtimeFlag() {
    return sumBy(this._collection, model => model.attributes["overtime_calculations"]["overtime_flag"])
  }

  @computed get _regularDuration() {
    return durationDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["regular_duration"]))
  }

  @computed get _regularCost() {
    return currencyDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["regular_cost"]))
  }

  @computed get _overtimeDuration() {
    return durationDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["overtime_duration"]))
  }

  @computed get _overtimeCost() {
    return currencyDisplay(sumBy(this._collection, model => model.attributes["overtime_calculations"]["overtime_cost"]))
  }

  _toggleDisabledWarning() {
    this.disabledWarningTarget.classList.toggle('d-none', !this._isDisabled)
    if (this.hasCostTarget) this.costTarget.classList.toggle('d-none', this._isDisabled)
    if (this.hasDurationTarget) this.durationTarget.classList.toggle('d-none', this._isDisabled)
  }

  _updateShiftCount() {
    var count = this._shiftCount
    var count_txt = count + " Shifts"
    if (this.hasShiftCountTarget) {
      this.shiftCountTarget.innerHTML = count_txt
      if (current.baseStore.modelName == "timesheet") {
        this.shiftCountTarget.remove();
      } else {
        this.durationTarget.removeAttribute('data-original-title')
        this.durationTarget.removeAttribute('data-toggle')
      }
    }
  }

  get _costVisible() {
    return current.roleSetting('wage') || this._myTotals
  }

  get _date() {
    if (!this.data.has('dateIndex')) return undefined
    return moment(current.dateRange.padded_start_date).add(this.data.get('dateIndex'), 'day').format('YYYY-MM-DD')
  }

  get _isDisabled() {
    return current.isTableRendition && this._store.hasNextPage
  }

  get _myTotals() {
    return current.parentAssignment && current.addonSetting('my_totals_enabled')
  }

  get _rowId() {
  	if (!this.data.has('rowId')) return undefined
  	return this.data.get('rowId') && parseInt(this.data.get('rowId')) || null
  }

  get _store() {
  	return current.baseStore
  }

  get _visible() {
    if (!current.isBaseModelName('shift', 'timesheet')) return false
    let settings = current.roleSetting('total_hours')
    return settings || this._myTotals
  }

  get _date_filtered_collection(){
	var collection = []

  }

  @computed get _shiftCount() {
    if (current.baseStore.modelName == "shift") {
      return sumBy(this._collection, model => model.attributes["quantity"])
    } else {
      return 0
    }
  }
}