import { Controller } from "stimulus"

export default class extends Controller {

	static targets = ['toggleField']

	initialize() {
		this.toggleFieldTargets.forEach((element, index) => {
			if (index != 0) element.insertAdjacentHTML('beforeEnd', "<a href='#' class='previous' data-action='setup--form#toggleFields' data-previous='true'>Previous Question</a>")
		})
	}

	toggleFields(event) {
		event.preventDefault()
		const wrapper = event.currentTarget.getAttribute('toggle-field-wrapper') || '.form-group'	
		const selectors = event.currentTarget.getAttribute('data-toggle-selectors')
		const previous = event.currentTarget.getAttribute('data-previous')
		if (selectors) this._toggleElements(selectors)
		const closestWrapper = $(event.currentTarget).closest(wrapper)
		const targetedWrapper = closestWrapper[previous ? 'prevAll' : 'nextAll'](wrapper).first()
		targetedWrapper.toggleClass('d-none')
		closestWrapper.addClass('d-none')
	}

	// PRIVATE
	_toggleElements(selectors) {
		JSON.parse(selectors).forEach((value) => {$(`${value}`).toggleClass('d-none')})
	}
 }