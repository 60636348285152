import { Controller } from "stimulus"
import { camelCase } from "lodash"
import pluralize from 'pluralize'
import { findController } from 'shared/utilities/controllers'
import 'jquery-ui/ui/widgets/draggable'

export default class extends Controller {
	connect() {
		this._initializeDraggable()
	}

	click(event) {
		if(event.altKey){
			this._model.save({quick_break: true})
		} else {
			this._remoteModalController.prepare(this._model.modalStyle)
			$.get(this._model._showPath)
		}
	}

// PRIVATE

	_initializeDraggable() {
		if (this._isDraggable) {
			$(this.element).draggable({
	      containment: '.drag-container',
	      delay: 100,
	      helper: event => {
	        let clone = this.element.cloneNode(true)
	        clone.style.width = `${this.element.getBoundingClientRect().width}px`
	        return clone
	      },
	      start: event => {
	      	let keepOriginal = event.shiftKey || (this._model !== undefined && this._model.attributes !== undefined && this._model.attributes.quantity > 1)
	      	if (!keepOriginal) this.element.classList.toggle('dragging-hidden', true)
	      	current.dragTarget = this._model
	      },
	      stop: event => {
	      	this.element.classList.toggle('dragging-hidden', false)
	      	// This timeout prevents clicks bubbling to the schedule-item-list controller in some browsers.
	      	setTimeout(() => current.dragTarget = null, 100)
	      },
	      zIndex: 1020
	    })
		}
	}

	get _isDraggable() {
		if (!this._model || !this._model.isDraggable || this._model.cloned) return false
		return current.isGridOrCalendarRendition && this._model.store.modelName == current.baseStore.modelName
	}
  
	get _model() {
		return this._store.find(this.element.dataset.id)
	}

	get _remoteModalController() {
		return findController('layouts--remote-modal')
	}

  get _store() {
  	return window[pluralize(camelCase(this.data.get('modelName')))]
  }
}