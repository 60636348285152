import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'hiddenInput']

  initialize() {
  	this.updateButtons()
  }

  clickButton(event) {
    this.value = event.currentTarget.getAttribute('data-value')
    this.updateButtons()
  }

  updateButtons() {
    this.buttonTargets.forEach(element => {
      element.classList.toggle("active", element.getAttribute('data-value') == this.value)
    })
  }

  get value() {
    return this.hiddenInputTarget.value || null
  }

  set value(value) {
    this.hiddenInputTarget.value = value 
    this.hiddenInputTarget.dispatchEvent(new Event('change'))
  }
}