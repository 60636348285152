import pluralize from 'pluralize'
import { includes, indexOf, intersection } from 'lodash' 
import { computed, toJS } from 'mobx' 

const filterModelConcern = (superclass) => class extends superclass {  
	isCollectionFiltered(attribute, ids = []) {
		if (ids.length <= 0) return true
		if (attribute.slice(0, -3) == this.store.modelName) return this._isCollection(ids)
		if (this.attributes[attribute] !== undefined) {
			return includes(ids, this.attributes[attribute])
		} else if (this.attributes[pluralize(attribute)] != undefined) {
			return intersection(ids, this.attributes[pluralize(attribute)]).length > 0
		} 
		return true
	}

	get isCollectionsFiltered() {
		return this._isAssignmentFiltered && this._isLocationFiltered && this._isPositionFiltered && this._isGroupFiltered
	}

	get isParentAssignmentCollectionsFiltered() {
		return this._isParentAssignmentLocationFiltered && this._isParentAssignmentPositionFiltered
	}

// PRIVATE

	_isCollection(ids = []) {
		if (ids.length <= 0) return true
		return includes(ids, this.id)
	}

	get _isAssignmentFiltered() {
		return this.isCollectionFiltered('assignment_id', current.filterAssignmentIds)
	}

	get _isLocationFiltered() {
		return this.isCollectionFiltered('location_id', current.filterLocationIds)
	}

	get _isPositionFiltered() {
		return this.isCollectionFiltered('position_id', current.filterPositionIds)
	}

	get _isGroupFiltered() {
		return this.isCollectionFiltered('group_id', current.filterGroupIds)
	}

	get _isParentAssignmentLocationFiltered() {
		if (!current.parentAssignment) return true
		return this.isCollectionFiltered('location_id', current.parentAssignment.location_ids)
	}

	get _isParentAssignmentPositionFiltered() {
		if (!current.parentAssignment) return true
		return this.isCollectionFiltered('position_id', current.parentAssignment.position_ids)
	}
}

export default filterModelConcern