import { computed } from 'mobx'
import Model from 'shared/models/model'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import timeframeModelConcern from 'shared/models/concerns/timeframe_model_concern'
import parentAssignmentModelConcern from 'shared/models/concerns/parent_assignment_model_concern'
import statusModelConcern from 'shared/models/concerns/status_model_concern'
import TimeOffScheduleItem from 'shared/components/time_off_schedule_item'
import TimeOffTableRow from 'shared/components/time_off_table_row'
import TimeOffPhoneTableRow from 'shared/components/time_off_phone_table_row'

export default class TimeOff extends statusModelConcern(parentAssignmentModelConcern(timeframeModelConcern(filterModelConcern(Model)))) {
	store = timeOffs

	@computed get isBaseFiltered() {
		return this.isCollectionsFiltered && this._variationFiltered && this.belongsToParentAssignment
	}

	@computed get isFiltered() {
		return this.isBaseFiltered && this._isStatusFiltered && !this._isDeniedHidden
	}

	@computed get isVisible() {
		if (!current.isAssignmentGridRendition) return this.isFiltered
		return this.isTimeframeFiltered && this.belongsToParentAssignment && !this._isDeniedHidden
	}

	get editPolicy() {
		return current.isSupervisor || current.isCurrentAssignment(this.attributes.assignment_id)
	}

	get phoneTableRow() {
		return new TimeOffPhoneTableRow(this).HTML
	}

	get scheduleItem() {
		if (current.baseStore.modelName == "timesheet") {
			if (this.attributes.status == "approved") {
				return new TimeOffScheduleItem(this).HTML
			} else {return null}
		} else {
			return new TimeOffScheduleItem(this).HTML
		}
	}

	get tableRow() {
		return new TimeOffTableRow(this).HTML
	}

// PRIVATE

	get _isDeniedHidden() {
		if (!current.isGridOrCalendarRendition) return false
		return !current.isBaseModelName('time_off') && this.attributes['status'] == 'denied'
	}

	get _variationFiltered() {
		return current.variationType == 'pending' ? (this.statusFilter('pending') && this.inFuture) : this.isTimeframeFiltered
	}
}