import { camelCase, indexOf, isEqual, mapValues, omit, pick } from 'lodash'
import { action, computed, observable, set, toJS } from 'mobx'
import URI from 'urijs'
import pluralize from 'pluralize'
import numeral from 'numeral'

export default class Current {
	@observable JSONData = {}
	@observable fetchRequests = []
	@observable blockFetchRequests = false
	@observable dragTarget = null

	@action abortPendingFetchRequests() {
		this.fetchRequests.forEach(r => r.abort())
		this.fetchRequests = []
	}

	addonSetting(addonKey) {
		if (!this.schedule) return false
		return this.schedule.addon_settings[addonKey]
	}

	roleSetting(roleKey) {
		if (!this.schedule) return false
		let settings = this.schedule.role_settings[roleKey]
		if (this.assignment.owner) {
			return settings['owner']
		} else {
			return settings[this.assignment.role]
		}
	}

	evaluatePolicy(policyString) {
		if (policyString == '') return true
		return eval(policyString.replace(/:/g, 'this.'))
	}

	fetchStores() {
		this.stores.forEach(s => s.fetch())
	}

	filterScope(key) {
		if (!this.filter.scopes) return null
		return this.filter.scopes[key]
	}

	@action hydrate(JSON) {
		set(this.JSONData, JSON)
		this._setMomentLocale()
		this._setNumeralLocale()
		document.dispatchEvent(new CustomEvent('current:hydrated'))
	}

	isBaseModelName(...modelNames) {
		return modelNames.includes(this.baseStore.modelName)
	}

	isCurrentAssignment(assignmentId) {
		if (!this.assignment) return false 
		return this.assignment.id == assignmentId
	}

	updateFilter(JSON, filterHTML) {
  	if (this._haveFilterCollectionsChanged(JSON) || this.isTableRendition) {
	  	Turbolinks.visit(window.location, {action: 'replace'})
		} else {
			this.JSONData.filter = JSON
	  	if (window.pageFiltersController) window.pageFiltersController.replaceHTML(filterHTML)
		}
	}

	@computed get allStoresHydrated() {
		return !this.stores.some(s => !s.hydratedAt)
	}

	get assignment() {
		return this.JSONData.assignment
	}

	get baseStore() {
		const controllerName = camelCase(this.JSONData.controller_name)
		return controllerName == 'dashboards' ? window.shifts : window[controllerName]
	}

	get dateRange() {
  	return this.JSONData.date_range
  }

	@computed get filter() {
		return this.JSONData.filter
	}

	@computed get filterAssignmentIds() {
		return this._normalizeFilterCollectionIds(this.filter.assignment_ids)
	}

	@computed get filterLocationIds() {
		return this._normalizeFilterCollectionIds(this.filter.location_ids)
	}

	@computed get filterPositionIds() {
		return this._normalizeFilterCollectionIds(this.filter.position_ids)
	}

	@computed get filterGroupIds() {
		return this._normalizeFilterCollectionIds(this.filter.group_ids)
	}

	get isAssignmentGridRendition() {
		return this.renditionType == 'grid' && this.rowType == 'assignments'
	}

	get isBillingLiaison() {
		return this.JSONData.billing_liaison
	}

	get isCalendarRendition() {
		return ['calendar', 'mobile_calendar'].includes(this.renditionType)
	}

	get isGridOrCalendarRendition() {
		return ['calendar', 'grid', 'mobile_calendar'].includes(this.renditionType)
	}

	get isTableRendition() {
		return this.renditionType == 'table'
	}

	get isTabletOrPhoneVariant() {
		return ['phone', 'tablet'].includes(this.JSONData.variant)
	}

	get isPhoneVariant() {
		return this.JSONData.variant == 'phone'
	}

	get isManager() {
		return this._hasRole('manager')
	}

	get isSupervisor() {
		return this._hasRole('supervisor')
	}

	get overtime_enabled() {
		return this.JSONData.overtime_enabled
	}

	get parentAssignment() {
		return this.JSONData.parent_assignment
	}

	get premium() {
		return this.JSONData.premium
	}

	get essentials() {
		return this.JSONData.essentials
	}

	get renditionType() {
		return this.JSONData.rendition_type || null
	}

	get rowAttribute() {
		return this.rowType ? `${pluralize.singular(this.rowType)}_id` : null
	}

	get rowIds() {
		return this.JSONData.row_ids || []
	}

	get rowType() {
		return this.JSONData.row_type || null
	}

	get schedule() {
		return this.JSONData.schedule
	}

	get printPreview() {
		return this.JSONData.print_preview
	}

	@computed get stores() {
		const stores = []
		if (this.isGridOrCalendarRendition) {
			if (this.isBaseModelName('shift')) {
				stores.push(events, timeOffs)
				if (current.parentAssignment || current.rowType == 'assignments') stores.push(availabilities)
			}
			if (this.isBaseModelName('time_off')) stores.push(timeOffRestrictions)
			if (this.isBaseModelName('timesheet')) stores.push(shifts,timeOffs)
		}
		stores.push(this.baseStore)
		return stores
	}

	get variationType() {
		return this.JSONData.variation_type || null
	}

// PRIVATE 

	_hasRole(role) {
		if (!this.assignment) return false
		const roles = ['employee', 'supervisor', 'manager']
		return indexOf(roles, role) <= indexOf(roles, this.assignment.role)
	}

	_haveFilterCollectionsChanged(filterJSON) {
		const collectionKeys = ['assignment_ids', 'location_ids', 'position_ids', 'group_ids', 'role_ids', 'time_off_category_ids', 'saved_filter_ids']
		const currentCollections = mapValues(pick(this.filter, collectionKeys), (ids) => toJS(ids))
  	const newCollections = pick(filterJSON, collectionKeys)
  	return !isEqual(currentCollections, newCollections)
	}

	_normalizeFilterCollectionIds(ids = []) {
		const clonedIds = toJS(ids)
		const blankIndex = indexOf(clonedIds, 'blank')
		if (blankIndex >= 0) clonedIds.splice(blankIndex, 1, null)
		return clonedIds
	}

	_setMomentLocale() {
		const dow = this.schedule && this.schedule.start_day_i <= 6 ? this.schedule.start_day_i : 0
		moment.updateLocale('en', {week: {dow: dow}})
	}

	_setNumeralLocale() {
		numeral.locales['custom'] = null
		if (this.schedule) {
			numeral.register('locale', 'custom', {
	    	delimiters: {thousands: this.schedule.currency.delimiter, decimal: this.schedule.currency.separator},
	    	currency: {symbol: this.schedule.currency.unit}
			});
			numeral.locale('custom');
		}
	}
}