import { Controller } from "stimulus"

export default class extends Controller {
    subscription = null

    initialize() {
        this._createSubscription()
    }

    disconnect() {
        if (this.subscription) window.cable.subscriptions.remove(this.subscription)
    }

    // PRIVATE

    _createSubscription() {
        var $element = $('[data-channel-subscribe="online-status"]');
        var schedule_id = $element.data("schedule-id");
        this.subscription = window.cable.subscriptions.create(
            {channel: 'OnlineStatusChannel', schedule: schedule_id},
            {received: data => {
                console.log(data)
                if (data.online == true) {
                    $('.icon-user-' + data.user).removeClass('color-gray').addClass('color-green')
                } else {
                    $('.icon-user-' + data.user).removeClass('color-green').addClass('color-gray')
                }
            }}
        )
    }

}