import { Controller } from "stimulus"
import { autorun } from 'mobx'

export default class extends Controller {
  connect() {
    this._autorunDisposer = autorun(() => this._toggleClasses())
    this._toggleDisconnectedClass(false)
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
    this._toggleDisconnectedClass(true)
  }

// PRIVATE

  _toggleClasses() {
    this.element.classList.toggle('loaded', current.allStoresHydrated)
  }

  _toggleDisconnectedClass(state) {
    this.element.classList.toggle('disconnected', state) 
  }
}