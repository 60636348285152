import ScheduleItem from 'shared/components/schedule_item'
import { html } from 'lit-html'

export default class TimesheetScheduleItem extends ScheduleItem {
	get _classes() {
		return {
			'not-approved': !this._attributes.approved
		}
	}

	get _details() {
		const result = []
		if ((current.rowType != 'assignments' && !current.parentAssignment) || this.model.isHiddenAssignment) result.push(this._assignmentName(this._attributes.assignment_attributes))
		if (current.rowType != 'positions') result.push(this._attributes.position_name)
		if (current.rowType != 'locations') result.push(this._attributes.location_name)
		result.push(this._detailNote())
		return result
	}

	get _indicators() {
		const result = [this._indicatorNote()]
		if (this._attributes.conflict) result.push(this._indicatorIcon('exclamation-circle', 'Error'))
		return result
	}

	get _innerHTML() {
		return html`
			${this._colorBorderHTML}
			${this._headingHTML}
			${this._breakHTML}
			${this._detailsHTML}
		`
	}
}