import { Controller } from "stimulus"

export default class extends Controller {
  submit(event) {
  	if (this._recaptchaV3Input) {
  		event.preventDefault()
  		grecaptcha.execute(this._recaptchaV3SiteKey, {action: 'accountCreate'}).then(token => {
  			this._recaptchaV3Input.value = token
  			railsUJS.fire(this.element, 'submit')
  		})
  	}
  }
  
// PRIVATE

	get _recaptchaV3Input() {
		return document.getElementById('g-recaptcha-response-accountCreate')
	}

	get _recaptchaV3SiteKey() {
		return this._recaptchaV3Input.getAttribute('data-sitekey')
	}
}