import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['allDayInput', 'endAt', 'endTime', 'startAt', 'startTime']

  initialize() {
  	this.toggleAllDay()
  }

  toggleAllDay() {
  	$(this.startAtTarget).find('label').text(this._allDay && 'Start Date' || 'Date')
  	$(this.endAtTarget).toggleClass('d-none', !this._allDay)
  	$(this.startTimeTarget).toggleClass('d-none', this._allDay)
  	$(this.endTimeTarget).toggleClass('d-none', this._allDay)
  }

  get _allDay() {
  	return this.allDayInputTarget.value == 'true'
  }
}