import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['filterInput', 'filterFields']  

  initialize() {
    this.toggleFilters()
  }

  toggleFilters() {
  	if (this.hasFilterInputTarget) {
	  	$(this.filterFieldsTarget).toggleClass('d-none', this.filterInputTarget.value != 'true')
  	}
  }
}