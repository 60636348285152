import Store from 'shared/stores/store'
import Shift from 'shared/models/shift'
import cellCollectionLookupStoreConcern from 'shared/stores/concerns/cell_collection_lookup_store_concern'
import refetchStoreConcern from 'shared/stores/concerns/refetch_store_concern'
import hiddenAssignmentsStoreConcern from 'shared/stores/concerns/hidden_assignments_store_concern'
import { action, computed } from 'mobx'
import URI from 'urijs'
import { JSONFetch } from 'shared/utilities/json_fetch'

export default class Shifts extends hiddenAssignmentsStoreConcern(refetchStoreConcern(cellCollectionLookupStoreConcern(Store))) {
  defaultOrder = 'timeframe'
  modelClass = Shift
  modelName = 'shift'
  refetchTrackedStoreNames = ['availabilities', 'shifts', 'timeOffs']

  @computed.struct get availableCollection() {
		return this.orderedCollection.filter(model => model.isAvailable)
	}

	@action fetch() {
		if (super.fetch()) {
			if (current.parentAssignment && current.isCalendarRendition) {
				JSONFetch(this._availableShiftsFetchPath, JSON => this.hydrate(JSON, true))
			}
		}
	}

	get newPolicy() {
  		return current.roleSetting('edit_shift')
	}

// PRIVATE 

	get _availableShiftsFetchPath() {
  	const uri = new URI(this._fetchPath)
  	uri.pathname(`${current.schedule.id}/shifts/available.json`)
  	return uri.href()
  }
}