import ScheduleItem from 'shared/components/schedule_item'
import { html } from 'lit-html'
import { durationDisplay } from 'shared/utilities/duration'

export default class ShiftScheduleItem extends ScheduleItem {
	get _classes() {
		return {
			'has-swap': this._attributes.swap_id,
			'not-assigned': !this._attributes.assignment_id,
			'not-published': !this._attributes.published,
			'not-confirmed': !this._attributes.confirmed && current.addonSetting('shift_confirmation'),
			'confirmed': this._attributes.confirmed && current.addonSetting('shift_confirmation')
		}
	}

	get _details() {
		const result = []
		if ((current.rowType != 'assignments' && !current.parentAssignment) || this.model.isHiddenAssignment) result.push(this._assignmentName(this._attributes.assignment_attributes))
		if (current.rowType != 'positions') result.push(this._attributes.position_name)
		if (current.rowType != 'locations') result.push(this._attributes.location_name)
		result.push(this._detailNote())
		return result
	}

	get _indicators() {
		const result = [this._indicatorNote()]
		if (current.addonSetting('shift_confirmation') && (this._attributes.assignment_attributes.employee || this._attributes.assignment_id == null)) {
			if (this._attributes.confirmed) {
				result.push(this._indicatorIcon('thumbs-up', 'Confirmed'))
			} else {
				result.push(this._indicatorIcon('hourglass-half', 'Pending'))
			}
		}
		if (this._attributes.conflict) result.push(this._indicatorIcon('exclamation-circle', 'Error'))	
		if (this._attributes.swap_id) result.push(this._statusIcon('status'))
		if (this._attributes.quantity > 1) result.push(html`<div class="quantity" data-tooltip="Shift Slots">${this._attributes.quantity}</div>`)
		return result
	}

	get _innerHTML() {
		return html`
			${this._colorBorderHTML}
			${this._headingHTML}
			${this._detailsHTML}
		`
	}
}