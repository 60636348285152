import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['integrationOptionFields', 'source']

    initialize() {
        this.integrationOptionFieldsTargets.forEach(wrapper => {
            wrapper.classList.add('d-none');
        })
        this.old_element = "";
        this.showIntegrationOption();
    }

    showIntegrationOption() {
        this.toggleIntegrationOption(this.old_element);
        this.old_element = this.sourceTarget.value;
        this.toggleIntegrationOption(this.old_element);
    }

    toggleIntegrationOption(integrationOptionClassName = ""){
        if (integrationOptionClassName != "") {
            var integrationOptionElements = document.getElementsByClassName(integrationOptionClassName)
            integrationOptionElements.forEach((element) => {
                element.classList.toggle("d-none");
            });
        }
    }
}