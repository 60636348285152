import { Controller } from "stimulus"
import { some, values } from 'lodash'

export default class extends Controller {
	static targets = ['ignoreConflictField', 'rule']

  initialize() {
  	this._update()
  }

  refresh(conflictHash) {
  	this.data.set('conflictHash', JSON.stringify(conflictHash))
  	this._update()
  }

  _update() {
  	this._toggleVisibility()
  	this._toggleIgnoreConflictField()
  	this._toggleRules()
  }

  _toggleIgnoreConflictField() {
  	if (this.hasIgnoreConflictFieldTarget) {
  		$(this.ignoreConflictFieldTarget).toggleClass('d-none', !this.hasValidationConflict)
  	}
  }

  _toggleRules() {
  	this.ruleTargets.forEach(ruleTarget => {
  		let key = $(ruleTarget).data('key')
  		$(ruleTarget).attr('class', `rule ${this.conflictHash[key]}`)
  	})
  }

  _toggleVisibility() {
  	$(this.element).toggleClass('d-none', !this.hasConflict)
  }

  get conflictHash() {
  	return JSON.parse(this.data.get('conflictHash'))
  }

  get hasConflict() {
  	return some(values(this.conflictHash))
  }

  get hasValidationConflict() {
  	return some(values(this.conflictHash), value => value == 'validate')
  }
}