import { Controller } from "stimulus"

export default class extends Controller {
  subscription = null
  
  initialize() {
  	this._createSubscription()
  	this._toggleBadge()
  }

  disconnect() {
  	if (this.subscription) window.cable.subscriptions.remove(this.subscription)
  }

	// PRIVATE 

	_createSubscription() {
		this.subscription = window.cable.subscriptions.create(
			{channel: 'AlertNotificationChannel'},
			{received: data => {
				this._unreadCount = data.unread_count
				this._toggleBadge()
			}}
		)
	}

	_toggleBadge() {
		this.element.classList.toggle('unread-badge', this._unreadCount > 0)
	}

	get _unreadCount() {
		return parseInt(this.data.get('unreadCount'))
	}

	set _unreadCount(value) {
		this.data.set('unreadCount', parseInt(value))
	}
}