import { Controller } from "stimulus"
import { findController } from 'shared/utilities/controllers'

export default class extends Controller {
  update(title) {
  	$(this.element).text(title)
  }
}

window.updateMetaTitle = (title) => {
	findController('helpers--meta-title').update(title)
}