import { computed } from 'mobx'
import Model from 'shared/models/model'
import { includes } from 'lodash'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import timeframeModelConcern from 'shared/models/concerns/timeframe_model_concern'
import parentAssignmentModelConcern from 'shared/models/concerns/parent_assignment_model_concern'
import statusModelConcern from 'shared/models/concerns/status_model_concern'
import SwapScheduleItem from 'shared/components/swap_schedule_item'
import SwapTableRow from 'shared/components/swap_table_row'
import SwapPhoneTableRow from 'shared/components/swap_phone_table_row'

export default class Swap extends statusModelConcern(parentAssignmentModelConcern(timeframeModelConcern(filterModelConcern(Model)))) {
	store = swaps

	@computed get isBaseFiltered() {
		return this.isCollectionsFiltered && this._variationFiltered && this.belongsToParentAssignment
	}

	@computed get isFiltered() {
		return this.isBaseFiltered && this._isStatusFiltered 
	}

	get editPolicy() {
		return true
	}

	get phoneTableRow() {
		return new SwapPhoneTableRow(this).HTML
	}

	get scheduleItem() {
		return new SwapScheduleItem(this).HTML
	}

	get tableRow() {
		return new SwapTableRow(this).HTML
	}

// PRIVATE

	get _variationFiltered() {
		return current.variationType == 'not_resolved' ? ((this.statusFilter('pending') || this.statusFilter('waiting')) && this.inFuture) : this.isTimeframeFiltered
	}
}