import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['futureInput', 'futureFields', 'duplicateDayInput']

  connect() {
    this.toggleFuture()
    this._disableCurrentWeekday()
  }

  toggleFuture() {
    $(this.futureFieldsTarget).toggleClass('d-none', this.futureInputTarget.value != 'true')
  }

// PRIVATE

	_disableCurrentWeekday() {
		const currentWeekdayInput = this.duplicateDayInputTargets.find(input => input.value == this._currentWeekday)
		currentWeekdayInput.disabled = true
		currentWeekdayInput.parentElement.classList.toggle('disabled', true)
	}

	get _currentWeekday() {
		return this.data.get('currentWeekday')
	}
}