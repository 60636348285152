import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['formStateWrapper', 'input']

  initialize() {
    this.toggleState()
  }

  toggleState() {
    this.formStateWrapperTargets.forEach(wrapper => {
      let unlessState = wrapper.getAttribute('data-unless-form-state')
    	let state = wrapper.getAttribute('data-form-state')
      wrapper.classList.toggle('d-none', unlessState ? JSON.parse(unlessState).includes(this._currentState) : this._currentState != state)
    })
  }

  get _currentState() {
    return this.inputTarget.value
  }
}