import { Controller } from "stimulus"
import { max } from 'lodash'

export default class extends Controller {
  static targets = ['coordinatesState', 'latitudeInput', 'longitudeInput', 'resourceFields', 'shiftInput', 'submitButton']

  connect() {
    this._fetchCoordinates()
  }

  toggleShift() {
  	$(this.resourceFieldsTarget).toggleClass('d-none', this._shiftIdValue != '')
  }

// PRIVATE 
  
  _disableSubmit(disabled) {
    this.submitButtonTarget.classList.toggle('disabled', disabled)
  }

	_fetchCoordinates() {
    if (this._hasCoordinateInputs) {
      this._disableSubmit(true)
      window.navigator.geolocation.getCurrentPosition(this._fetchCoordinatesSuccess, this._fetchCoordinatesError)
    }
  }

  _fetchCoordinatesError = (error) => {
    this._toggleCoordinatesState('error')
    this._disableSubmit(false)
  }

  _fetchCoordinatesSuccess = (position) => {
    this.latitudeInputTarget.value = position.coords.latitude
    this.longitudeInputTarget.value = position.coords.longitude
    this._toggleCoordinatesState('success')
    this._disableSubmit(false)
  }

  _toggleCoordinatesState(state) {
    this.coordinatesStateTargets.forEach(element => {
      element.classList.toggle('d-none', element.getAttribute('data-state') != state)
    })
  }

  get _hasCoordinateInputs() {
    return this.hasLatitudeInputTarget && this.hasLongitudeInputTarget
  }

  get _shiftIdValue() {
  	return this.shiftInputTargets.find(input => $(input).is(':checked')).value
  }
}