import { Controller } from "stimulus"
import { findController } from 'shared/utilities/controllers'

export default class extends Controller {
  static targets = ['accountScript', 'pageScript', 'userScript']

  initialize() {
  	this.trackEvents(this._eventsJSON)   
    this.data.set('events', '')
  }

  disconnect() {
    $(this.accountScriptTarget).html('')
    $(this.pageScriptTarget).html('')
    $(this.userScriptTarget).html('')
  }

  trackEvents(eventsJSONArray) {
  	eventsJSONArray.forEach(event => {
      this._trackEvent(event.category, event.action, event.properties)
  	})
  }

  _trackEvent(category, action, properties) {
    if (typeof window.ga === 'function') {
        window.gtag('config', 'GA_MEASUREMENT_ID', {'user_id': `${current.JSONData.user.id}`})
        window.gtag('event', `${category}-${action}`, {'event_category': 'All'})
    }
  }

  get _eventsJSON() {
  	return JSON.parse(this.data.get('events') || '[]')
  }
}

window.trackAnalyticsEvents = (eventsJSONArray) => {
  findController('layouts--analytics').trackEvents(eventsJSONArray)
}