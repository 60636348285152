import { Controller } from "stimulus"
import URI from 'urijs'

export default class extends Controller {
  show() {
    if (window.Intercom) {
	  	if (this._shouldRedirect) {
        Turbolinks.visit(this._redirectPath)
      } else {
        window.Intercom('startTour', this._tourId)
      }
  	} else {
  		alert(`Intercom is not loaded. Please contact support if you see this error message. (Tour #${this._tourId})`)
  	}
  }

  get _redirectPath() {
    const uri = new URI(window.location)
    uri.removeQuery(/^preloaded_remote_modal/)
    if (this._tourRendition) uri.setQuery({rendition: this._tourRendition})
    if (this._tourRowType) uri.setQuery({row_type: this._tourRowType})
    if (this._tourInterval) uri.setQuery({interval: this._tourInterval})
    uri.setQuery({product_tour_id: this._tourId})
    return uri.href()
  }

  get _shouldRedirect() {
    if (this._tourRendition && this._tourRendition != current.renditionType) return true
    if (this._tourRowType && this._tourRowType != current.rowType) return true
    if (this._tourInterval && this._tourInterval != current.dateRange.interval) return true
    return false
  }

  get _tourData() {
    return JSON.parse(this.data.get('data'))
  }

  get _tourId() {
    return parseInt(this._tourData.id)
  }

  get _tourInterval() {
    return this._tourData.interval
  }

  get _tourRendition() {
    return this._tourData.rendition
  }

  get _tourRowType() {
    return this._tourData.row_type  
  }
}