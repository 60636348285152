import TableRow from 'shared/components/table_row'

export default class TimeOffTableRow extends TableRow {
	columns = timeOffs.columns

// PRIVATE
	
	get _assignmentNameCell() {
		return this._assignmentNameWithAvatar(this.model.attributes.assignment_attributes)
	}

	get _createdAtCell() {
		return this._timestampFormat(this.model.attributes.created_at)
	}

	get _dateCell() {
		return this._allDayDateFormat
	}

	get _locationsCell() {
		return this.model.attributes.location_names
	}

	get _noteCell() {
		return this.model.attributes.note
	}

	get _positionsCell() {
		return this.model.attributes.position_names
	}

	get _updatedAtCell() {
		return this._timestampFormat(this.model.attributes.updated_at)
	}

	get _statusCell() {
		return this._labelFormat('status')
	}

	get _timeCell() {
		return this._allDayTimeFormat
	}

	get _categoryNameCell() {
		return this.model.attributes.category_name
	}
}