import { action, computed, observable } from 'mobx'
import { compact, orderBy, replace } from 'lodash'
import URI from 'urijs'
import pluralize from 'pluralize'
import { JSONFetch } from 'shared/utilities/json_fetch'

export default class Store {
	@observable collection = []
	@observable hydratedAt = null
	@observable paginationAdjustmentIds = []
	columns = {}
	fetchingNextPages = false
	hasAllDayAttribute = false
	initialFetchInitiated = false
	modelClass = null
	modelName = null
	nextPage = 1
	order = null
	searchQuery = null
	sort = null

	@action createModel(modelJSON) {
		const model = new this.modelClass(modelJSON)
		this.collection.push(model)
		this._addRefetchAssignment(model)
		return model
	}

	@action createCollection(collectionJSON) {
		collectionJSON.forEach(modelJSON => this.createModel(modelJSON))
	}

	@action destroyModel(modelId) {
		const model = this.find(modelId)
		if (model) {
			this.collection.remove(model)
			this._addRefetchAssignment(model)
			this._addPaginationAdjustmentId(modelId)
		}
		return model
	}

	@action destroyCollection(collectionIds) {
		collectionIds.forEach(modelId => this.destroyModel(modelId))
	}

	@action fetch() {
		if (this.initialFetchInitiated && !this.hasNextPage) return false
		return this.initialFetchInitiated = JSONFetch(this._fetchPath, JSON => this.hydrate(JSON))
	}

  find(id) {
		return this.collection.find(model => model.id == parseInt(id))
	}

	@action hydrate(JSON, secondaryHydration = false) {
		this.nextPage = JSON.meta.next_page
		const newCollection = compact(JSON.data.map(modelJSON => this.find(modelJSON.id) ? null : new this.modelClass(modelJSON)))
		this.collection = this.collection.concat(newCollection)
		if (!current.isTableRendition) this._fetchNextPages()
		if (!secondaryHydration) this._resetHydratedAt()
	}

	@action updateModel(modelJSON) {
		this.destroyModel(modelJSON['id'])
		return this.createModel(modelJSON)
	}

	@action updateCollection(collectionJSON) {
		collectionJSON.forEach(modelJSON => this.updateModel(modelJSON))
	}

	@action reset() {
		this.collection = []
		this.fetchingNextPages = false
		this.hydratedAt = null
		this.initialFetchInitiated = false
		this.paginationAdjustmentIds = []
		this.columns = {}
		this.nextPage = 1
		this.order = null
		this.sort = null
	}

	@computed.struct get baseFilteredCollection() {
		return this.orderedCollection.filter(model => model.isBaseFiltered)
	}

	@computed.struct get filteredCollection() {
		return this.orderedCollection.filter(model => model.isFiltered)
	}

	@computed get filteredIds() {
		return this.filteredCollection.map(model => model.id)
	}

	get hasNextPage() {
		return this.nextPage > 1
	}

	@computed get ids() {
		return this.collection.map(model => model.id)
	}

	get newPath() {
		if (this.modelName == 'shift' && current.essentials == false) return new URI(`${current.schedule.id}/bulk/${this.modelName}/new?rendition_type=${current.renditionType}&row_type=${current.rowType}`).href()
		return new URI(`${current.schedule.id}/${pluralize(this.modelName)}/new`).href()
	}

	@computed.struct get orderedCollection() {
		return orderBy(this.collection, this._orderArray, this._sortArray)
	}

	@computed.struct get visibleCollection() {
		return this.orderedCollection.filter(model => model.isVisible)	
	}

// PRIVATE 

	_addRefetchAssignment(model) {
		shifts.addRefetchAssignment(model)
		timesheets.addRefetchAssignment(model)
	}

	_addPaginationAdjustmentId(itemId) {
    if (this.paginationAdjustmentIds.includes(parseInt(itemId))) return true
    this.paginationAdjustmentIds.push(parseInt(itemId))
  } 

  _fetchNextPages() {
		if (this.hasNextPage) {
			this.fetch()
			return this.fetchingNextPages = true
		} else {
			return this.fetchingNextPages = false
		} 
	}

	_resetHydratedAt() {
		this.hydratedAt = momentUTC().valueOf()
		this.paginationAdjustmentIds = []
	}

	get _baseFetchURI() {
		return new URI(`${current.schedule.id}/${pluralize(this.modelName)}`)
	}

	get _fetchPath() {
  	const uri = this._baseFetchURI
	const urlParams = new URLSearchParams(window.location.search)
  	uri.setQuery({page: this.nextPage, limit: 101, pagination_adjustment: this.paginationAdjustmentIds.length})
  	if (this._order) uri.setQuery({order: this._order, sort: this._sort})
  	if (this.searchQuery) uri.setQuery({query: this.searchQuery})
  	if (current.renditionType) uri.setQuery({rendition: current.renditionType})
  	if (current.dateRange) uri.setQuery({start_date: current.dateRange.padded_start_date, end_date: current.dateRange.padded_end_date})
  	if (current.parentAssignment) uri.setQuery({parent_assignment_id: current.parentAssignment.id})
  	if (current.isAssignmentGridRendition) uri.setQuery({assignment_grid: true})
  	if (current.variationType) uri.setQuery({variation: current.variationType})
	if (urlParams.has('role')) uri.setQuery({role: urlParams.get('role')})
  	return uri.suffix('json').href()
  }

  get _order() {
	const urlParams = new URLSearchParams(window.location.search)
  	return urlParams.get('order') || this.order || this.defaultOrder
  }

	get _orderArray() {
		if (!this.collection.length) return []
		return this.collection[0].orderAttributes[this._order].map((v,i) => `orderAttributes.${this._order}[${i}]`).concat('id')
	}	

	get _sort() {
	const urlParams = new URLSearchParams(window.location.search)
  	return urlParams.get('sort') || this.sort || this.defaultSort || 'asc'
  }

	get _sortArray() {
		if (!this.collection.length) return []
		return this.collection[0].orderAttributes[this._order].map(v => this._sort).concat('asc')
	}
}