import Store from 'shared/stores/store'
import TimeOff from 'shared/models/time_off'
import cellCollectionLookupStoreConcern from 'shared/stores/concerns/cell_collection_lookup_store_concern'

export default class TimeOffs extends cellCollectionLookupStoreConcern(Store) {
  defaultOrder = 'timeframe'
  hasAllDayAttribute = true
  modelClass = TimeOff
  modelName = 'time_off'

  get newPolicy() {
		return true
	}
}