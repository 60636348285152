import TableRow from 'shared/components/table_row'
import { currencyDisplay } from 'shared/utilities/currency'

export default class ShiftTableRow extends TableRow {
	columns = shifts.columns

// PRIVATE

	get _assignmentNameCell() {
		return this._assignmentNameWithAvatar(this.model.attributes.assignment_attributes)
	}

	get _breakTimeCell() {
		return this._durationFormat(this.model.attributes.break_time)
	}

	get _conflictCell() {
		return this._conflictFormat
	}

	get _costCell() {
		var value = ''
		if (current.premium && current.overtime_enabled) {
			value = currencyDisplay(this.model.attributes["overtime_calculations"]["regular_cost"] + this.model.attributes["overtime_calculations"]["overtime_cost"])
		} else {
			value = currencyDisplay(this.model.attributes.cost)
		}
		return value
	}

	get _createdAtCell() {
		if (this.model.attributes.show_object) return this._timestampFormat(this.model.attributes.created_at)
	}

	get _dateCell() {
		return this._dateFormat(this.model.attributes.start_at)
	}

	get _durationCell() {
		var value = ''
		if (current.premium && current.overtime_enabled) {
			value = this._durationFormat(this.model.attributes["overtime_calculations"]["regular_duration"] + this.model.attributes["overtime_calculations"]["overtime_duration"])
		} else {
			value = this._durationFormat(this.model.attributes.duration)
		}
		return value
	}

	get _locationCell() {
		return this.model.attributes.location_name
	}

	get _noteCell() {
		const value_hash = this.model.attributes.note
		var value = ''
		if (current.isManager) {
			value = value_hash["all"]
		} else {
			value = value_hash["public"]
		}
		return value
	}

	get _positionCell() {
		return this.model.attributes.position_name
	}

	get _publishedCell() {
		return this._booleanFormat(this.model.attributes.published)
	}

	get _confirmedCell() {
		return this._booleanFormat(this.model.attributes.confirmed)
	}

	get _updatedAtCell() {
		if (this.model.attributes.show_object) return this._timestampFormat(this.model.attributes.updated_at)
	}

	get _timeCell() {
		if (this.model.attributes.show_object) return this._timeFormat(this.model.attributes.start_at, this.model.attributes.end_at)
	}

	get _overtimeCostCell() {
		if (current.premium && current.overtime_enabled) {
			return currencyDisplay(this.model.attributes["overtime_calculations"]["overtime_cost"])
		} else {
			return currencyDisplay(0)
		}
	}

	get _overtimeDurationCell() {
		if (current.premium && current.overtime_enabled) {
			return this._durationFormat(this.model.attributes["overtime_calculations"]["overtime_duration"])
		} else {
			return this._durationFormat(0)
		}
	}

	get _regularDurationCell() {
		if (current.premium && current.overtime_enabled) {
			return this._durationFormat(this.model.attributes["overtime_calculations"]["regular_duration"])
		} else {
			return this._durationFormat(this.model.attributes.duration)
		}
	}

	get _regularCostCell() {
		if (current.premium && current.overtime_enabled) {
			return currencyDisplay(this.model.attributes["overtime_calculations"]["regular_cost"])
		} else {
			return currencyDisplay(this.model.attributes.cost)
		}
	}
}