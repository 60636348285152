import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['allDayInput', 'effectiveEndAtField', 'occurForeverInput', 'timeFields']

  initialize() {
  	this.toggleAllDay()
  	this.toggleOccurForever()
  }

  toggleAllDay() {
  	$(this.timeFieldsTarget).toggleClass('d-none', this.allDayInputTarget.value == 'true')
  }

  toggleOccurForever() {
  	this.effectiveEndAtFieldTarget.classList.toggle('d-none', this.occurForeverInputTarget.value == 'true')
  }
}