import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['industryInput', 'categoryInput']

  initialize() {
  	this._updateCategories()
  }

  industryChanged() {
  	this._updateCategories()
  }

  _updateCategories() {
  	if (this._industryLabel) {
			this._categoryInput.attr('disabled', false)
			this._categoryInput.find('optgroup').each((index, element) => {
				let optgroup = $(element)
				optgroup.toggleClass('d-none', this._industryLabel != optgroup.attr('label'))
			})
			if (this._categoryInput.find('option:selected').closest('optgroup').attr('label') != this._industryLabel) {
				this._categoryInput.val('')
			}
		} else {
			this._categoryInput.attr('disabled', true)
			this._categoryInput.val('')
		}
  }

  get _industryLabel() {
  	return $(this.industryInputTarget).find('option:selected').text()
  }

  get _categoryInput() {
  	return $(this.categoryInputTarget)
  }
}