import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['clearButton', 'input']

  initialize() {
  	this.toggleClearButton()
  }

  clear(event) {
  	event.stopPropagation()
  	this.inputTarget.value = null
  	this.toggleClearButton()
  	this.inputTarget.dispatchEvent(new Event('keyup'))
    this._submitForm()
  }

  toggleClearButton() {
  	$(this.clearButtonTarget).toggleClass('d-none', !this.inputTarget.value)
  }

// PRIVATE
  
  _submitForm() {
    if (this.data.get('searchForm')) {
      let form = this.element.closest('form')
      if (form.getAttribute('data-remote')) {
        railsUJS.fire(form, 'submit')
      } else {
        form.classList.toggle('pulsing', true)
        form.submit()
      }
    }
  }
}