import { html } from 'lit-html'
import { classMap } from 'lit-html/directives/class-map'
import { styleMap } from 'lit-html/directives/style-map'
import { kebabCase } from 'lodash'

export function modelWrapper(model, content, options = {}) {
	if(!current.isPhoneVariant && current.baseStore.modelName == "assignment" && (current.roleSetting('edit_member'))) {
		return html`<div class='cell assignment-cell round assignment-checkbox round'>
		<input type='checkbox' id="checkbox_${model.id}" style="display:none" onchange="selectTeammate(this)" class="assignment_chk" value="${model.id}">
		<label for="checkbox_${model.id}"></label></div>
		<a
		class="${classMap(Object.assign(options['classes'] || {}, {[kebabCase(model.store.modelName)]: true}))} assignment-table"
		data-id="${model.id}"
		data-controller="helpers--model-wrapper"
		data-target="helpers--mobile-pull-to-refresh.observable"
		data-helpers--model-wrapper-model-name="${model.store.modelName}"
		data-action="helpers--model-wrapper#click"
		data-tooltip="${options['tooltip'] || ''}"
		data-html="${options['html'] || 'false'}"
		style=${styleMap(options['styles'])}>
		${content}
		</a>
	`
	} else {
		let model_id = model.id
		if (model.attributes.show_object != undefined && !model.attributes.show_object) model_id = model.attributes.sub_id
		return html` 
			<a
			class=${classMap(Object.assign(options['classes'] || {}, {[kebabCase(model.store.modelName)]: true}))}
			data-id="${model_id}"
			data-controller="helpers--model-wrapper"
			data-target="helpers--mobile-pull-to-refresh.observable"
			data-helpers--model-wrapper-model-name="${model.store.modelName}"
			data-action="helpers--model-wrapper#click"
			data-tooltip="${options['tooltip'] || ''}"
			data-html="${options['html'] || 'false'}"
			style=${styleMap(options['styles'])}>
			${content}
			</a>
		`
	}
}