import { action, observable, reaction, toJS } from 'mobx'
import URI from 'urijs'
import { JSONFetch } from 'shared/utilities/json_fetch'

const refetchStoreConcern = (superclass) => class extends superclass {  
	@observable refetchAssignmentIds = []
	refetchTrackedStoreNames = []
	refetchPaused = false

	constructor() {
		super()
		reaction(() => toJS(this.refetchAssignmentIds), (ids) => this.refetch())
	}

	@action refetch() {
		if (this.refetchAssignmentIds.length > 0 && !this.refetchPaused) {
			JSONFetch(this._refetchPath, JSON => {
				this.refetchPaused = true
				this.updateCollection(JSON.data)
				this.refetchAssignmentIds.clear()
				this.refetchPaused = false
			})
		}
	}

// PRIVATE 

	addRefetchAssignment(model) {
		if (current.isBaseModelName(this.modelName)) {
			const assignmentId = model.originalAttributes.assignment_id
			if (assignmentId && this._refetchTrackedStores.includes(model.store)) {
				if (this.refetchAssignmentIds.includes(parseInt(assignmentId))) return true
	    	this.refetchAssignmentIds.push(parseInt(assignmentId))
			}
		}
	}

	get _refetchPath() {	
		const uri = this._baseFetchURI.setQuery({start_date: current.dateRange.padded_start_date, end_date: current.dateRange.padded_end_date})
		return uri.setQuery({'refetch_assignment_ids[]': this.refetchAssignmentIds}).href()
  } 

  get _refetchTrackedStores() {
  	return this.refetchTrackedStoreNames.map(name => window[name])
  }
}

export default refetchStoreConcern