import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['hasAddressInput', 'addressFields']

  initialize() {
    this.toggleHasAddress()
  }

  toggleHasAddress() {
    $(this.addressFieldsTarget).toggleClass('d-none', this.hasAddressInputTarget.value != 'true')
  }
}