import { Controller } from "stimulus"
import { action, computed } from 'mobx'
import { findController } from 'shared/utilities/controllers'

export default class extends Controller {
  static targets = ['emptyState', 'item', 'list', 'nextPageLink']
  paginationAdjustmentIds = []

  initialize() {
    window[`${this._collectionName}ListController`] = this
    this._scrollToBottom()
  }

  @action appendToList(event) {
    this.nextPageLinkTarget.remove()
    this._appendItems(event)
    this._replacenextPageLink(event)
    this.paginationAdjustmentIds = []
  }

  @action createItem(itemHTML) {
    this.listTarget.prepend(this._itemFragment(itemHTML))
    this._toggleEmptyState()
  }

  @action destroyItem(itemId) {
    const item = this._findItem(itemId)
    if (item) {
      item.remove()
      this._addPaginationAdjustmentId(itemId)
      this._toggleEmptyState()
    }
  }

  @action updateItem(itemId, itemHTML) {
    const item = this._findItem(itemId)
    if (item) {
      this.listTarget.replaceChild(this._itemFragment(itemHTML), item)
      this._addPaginationAdjustmentId(itemId)
      this._toggleEmptyState()
    }
  }

// PRIVATE

  get _collectionName() {
    return this.data.get('collectionName')
  }

  get _hasItems() {
    return this.itemTargets.length > 0
  }

  get _nextPageLinkController() {
    return this.hasNextPageLinkTarget ? findController('helpers--links', this.nextPageLinkTarget) : null
  }

  _addPaginationAdjustmentId(itemId) {
    if (this.paginationAdjustmentIds.includes(itemId)) return true
    this.paginationAdjustmentIds.push(itemId)
    if (this._nextPageLinkController) this._nextPageLinkController.setParams({pagination_adjustment: this.paginationAdjustmentIds.length})
  }

  _appendItems(event) {
    const node = event.detail[0].querySelector("[data-target='renditions--list.list']")
    const fragmentArray = []
    node.childNodes.forEach(itemNode => {
      var item = this._findItem(itemNode.getAttribute('data-id'))
      if (!item) fragmentArray.push(itemNode.outerHTML)
    })
    const fragment = document.createRange().createContextualFragment(fragmentArray.join(''))
    this.listTarget.appendChild(fragment)
  }

  _findItem(itemId) {
    return this.itemTargets.find(item => item.getAttribute('data-id') == itemId)
  }

  _itemFragment(itemHTML) {
    return document.createRange().createContextualFragment(itemHTML)
  }

  _replacenextPageLink(event) {
    const node = event.detail[0].querySelector("[data-target='renditions--list.nextPageLink']")
    const fragment = node ? document.createRange().createContextualFragment(node.outerHTML) : null
    if (fragment) this.element.appendChild(fragment)
  }

  _scrollToBottom() {
    if (this._collectionName == 'comments') {
      // The scrollContainer will vary depending on mobile or web.
      const scrollContainers = [$('.page-container'), $(this.element)]
      scrollContainers.forEach(container => {
        container.scrollTop(container[0].scrollHeight)  
      })
    }
  }

  _toggleEmptyState() {
    this.emptyStateTarget.classList.toggle('d-none', this._hasItems)
    if (this.hasNextPageLinkTarget) this.nextPageLinkTarget.classList.toggle('d-none', !this._hasItems)
  }
}