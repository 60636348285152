import { Controller } from "stimulus"

export default class extends Controller {
	initialize() {
    window.pageFiltersController = this
  }

  replaceHTML(pageFiltersHTML) {
  	$(this.element).replaceWith(pageFiltersHTML)
  }
}