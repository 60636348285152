import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { camelCase, replace } from 'lodash'

export default class extends Controller {
  static targets = ['count']

  connect() {
    this._autorunDisposer = autorun(() => this._render())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

// PRIVATE

  _render() {
    if (current.isTableRendition && !(!current.isPhoneVariant && current.baseStore.modelName == "assignment")) return false
    const count = this._collection.length
  	if (current.baseStore.hydratedAt) this.countTarget.innerHTML = count
  }

  @computed.struct get _collection() {
    if (current.baseStore.modelName == "shift" || current.baseStore.modelName == "timesheet"){
        return current.baseStore.collection.filter(model => model.isBaseFiltered && model[this._scopeFunction](this._scopeValue) && model.attributes.show_object)
    } else {
        return current.baseStore.collection.filter(model => model.isBaseFiltered && model[this._scopeFunction](this._scopeValue))
    }
  }

  get _scopeName() {
  	return this.data.get('scopeName')
  }

  get _scopeFunction() {
  	return `${camelCase(replace(this._scopeName, `${current.baseStore.modelName}_`, ''))}Filter`
  }

  get _scopeValue() {
  	return this.data.get('scopeValue')
  }
}