import ScheduleItem from 'shared/components/schedule_item'
import { html } from 'lit-html'

export default class TimeOffScheduleItem extends ScheduleItem {
	get _details() {
		const result = []
		if (current.rowType != 'assignments' && !current.parentAssignment || current.baseStore.modelName == "timesheet" || current.baseStore.modelName == "shift") {
			var name = this._assignmentName(this._attributes.assignment_attributes)
			if (this._attributes.category_name != null) name = name + ' - ' + this._attributes.category_name
			result.push(name)
		}
		result.push(this._detailNote())
		return result
	}

	get _headingIcon() {
		return 'tree-palm'
	}

	get _indicators() {
		return [this._statusIcon('status'), this._indicatorNote()]
	}

	get _innerHTML() {
		return html`
			${this._headingHTML}
			${this._detailsHTML}
		`
	}
}