import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['map', 'mapWarning']

  initialize() {
  	this._initializeMap()
  }

// PRIVATE 
	
	_initializeMap() {
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 3,
      center: this._hasCoordinates ? this._coordinates : {lat: 39.8283, lng: -98.5795},
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false
    })
    if (this._hasCoordinates) {
      const marker = new google.maps.Marker({position: this._coordinates, map: map})
      map.setZoom(15)
    } else {
      this.mapWarningTarget.classList.toggle('d-none', false)
    }
  }

  get _coordinates() {
    return {lat: parseFloat(this.data.get('latitude')), lng: parseFloat(this.data.get('longitude'))}
  }

  get _hasCoordinates() {
    return this._coordinates['lat'] && this._coordinates['lng']
  }
}