import PhoneTableRow from 'shared/components/phone_table_row'

export default class TimeOffPhoneTableRow extends PhoneTableRow {	
	get _avatar() {
		return this._assignmentAvatar(this.model.attributes.assignment_attributes)
	}

	get _details() {
		return [this._allDayDateTimeFormat, this._detailNote()]
	}

	get _headingLabel() {
		return this._labelFormat('status')
	}

	get _name() {
		return this._assignmentName(this.model.attributes.assignment_attributes)
	}
}