import { computed } from 'mobx'

const hiddenAssignmentsStoreConcern = (superclass) => class extends superclass {  
	@computed.struct get baseFilteredHiddenAssignmentsCollection() {
		return this.baseFilteredCollection.filter(model => model.isHiddenAssignment)
	}

	@computed.struct get filteredHiddenAssignmentsCollection() {
		return this.filteredCollection.filter(model => model.isHiddenAssignment)
	}

	@computed get _dateGroupedBaseFilteredHiddenAssignmentsCollection() {
		return this._group(this.baseFilteredHiddenAssignmentsCollection, ['date'])
	}

	@computed get _dateGroupedFilteredHiddenAssignmentsCollection() {
		return this._group(this.filteredHiddenAssignmentsCollection, ['date'])
	}
}

export default hiddenAssignmentsStoreConcern