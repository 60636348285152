import 'bootstrap/dist/js/bootstrap'
import "core-js/stable"
import "regenerator-runtime/runtime"
import '../packs/pagination'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import '../shared/initializers/store_initializer.js'
import '../shared/initializers/rails_initializer.js'
import '../shared/initializers/turbolinks_initializer.js'
import '../shared/initializers/moment_initializer.js'
import '../shared/initializers/local_time_initializer.js'
import '../shared/initializers/tooltip_initializer.js'
import '../shared/initializers/capacitor_initializer.js'
import '../shared/initializers/cable_initializer.js'

import $ from 'jquery'
window.jQuery = $
window.$ = $

const application = Application.start()
window.application = application

const sharedControllers = require.context("../shared/controllers", true, /\.js$/)
application.load(definitionsFromContext(sharedControllers))