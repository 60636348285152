import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['linksWrapper']

  initialize() {
  	$(this.element).on('mouseleave', event => this._toggleClass(true))
  }

	toggle() {
		this._toggleClass(!this.linksWrapperTarget.classList.contains('d-none'))
	}

	// PRIVATE 

	_toggleClass(state) {
		this.linksWrapperTarget.classList.toggle('d-none', state)
	}
}