import { Controller } from "stimulus"
import { action } from 'mobx'
import URI from 'urijs'
import 'jquery-ui/ui/widgets/sortable'
import pluralize from 'pluralize'

export default class extends Controller {
  connect() {
    $(this.element).sortable({
			axis: 'y',
			update: (event, ui) => { 
				this._reorder(event, ui)
			}
		})
	}

  @action _reorder(event, ui) {
  	const item = ui.item[0]
  	let currentCustomOrder = this._customOrder(item)
  	let prevItem = ui.item.prev()[0]
  	let newCustomOrder = prevItem ? this._customOrder(prevItem) : 0
  	if (currentCustomOrder > newCustomOrder) newCustomOrder += 1
		item.dataset.customOrder = newCustomOrder
		if (currentCustomOrder > newCustomOrder) {
			this._childrenArray.filter(i => this._customOrder(i) >= newCustomOrder && i.dataset.id != item.dataset.id).forEach(i => {
				if (this._customOrder(i) < currentCustomOrder) i.dataset.customOrder = this._customOrder(i) + 1
			})
		} else { 
			this._childrenArray.filter(i => this._customOrder(i) <= newCustomOrder && i.dataset.id != item.dataset.id).forEach(i => {
				if (this._customOrder(i) > currentCustomOrder) i.dataset.customOrder = this._customOrder(i) - 1
			})
		}
		this._save(item)
  }

// PRIVATE

	_customOrder(item) {
		return parseInt(item.dataset.customOrder)
	}

	_save(item) {
		$.ajax({
			url: `${current.schedule.id}/${pluralize(this._modelName)}/${item.dataset.id}`,
			data: {[this._modelName]: {custom_order: item.dataset.customOrder}},
			method: 'PATCH',
			beforeSend: railsUJS.CSRFProtection,
			context: this,
			error: (response) => {
				alert('The custom order failed to save. Please try again.')
			}
		})
	}

	get _childrenArray() {
		return Array.from(this.element.children)
	}

	get _modelName() {
		return this.data.get('modelName')
	}
}