import Store from 'shared/stores/store'
import Swap from 'shared/models/swap'
import cellCollectionLookupStoreConcern from 'shared/stores/concerns/cell_collection_lookup_store_concern'

export default class Swaps extends cellCollectionLookupStoreConcern(Store) {
  defaultOrder = 'timeframe'
  modelClass = Swap
  modelName = 'swap'

  get newPolicy() {
		return false
	}
}