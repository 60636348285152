import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'

export default class extends Controller {
  static targets = ['grid', 'hiddenAssignmentsRow', 'row']

  connect() {
    if (!current.allStoresHydrated) current.fetchStores()
    this._autorunDisposer = autorun(() => this._toggleHiddenAssignmentsRow())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

// PRIVATE

  _toggleHiddenAssignmentsRow() {
    if (current.isAssignmentGridRendition) {
      this.hiddenAssignmentsRowTarget.classList.toggle('d-none', !this._showHiddenAssignmentsRow)
    }
  }

  @computed get _showHiddenAssignmentsRow() {
    return current.baseStore.baseFilteredHiddenAssignmentsCollection.length > 0
  }
}