import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['button', 'field']

  copy() {
  	this.fieldTarget.select()
    document.execCommand('copy')
		const toolTipHandler = $(this.buttonTarget).tooltip({title: 'Copied!', trigger: 'manual'})
		toolTipHandler.tooltip('show')
		setTimeout(() => toolTipHandler.tooltip('hide'), 2000)
  }
}