import { computed } from 'mobx'
import Model from 'shared/models/model'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import parentAssignmentModelConcern from 'shared/models/concerns/parent_assignment_model_concern'
import AvailabilityScheduleItem from 'shared/components/availability_schedule_item'

export default class Availability extends parentAssignmentModelConcern(filterModelConcern(Model)) {
	store = availabilities

	onDate(date) {
		return this._overlapsEffectiveRange(momentUTC(date).startOf('day'), momentUTC(date).endOf('day')) && this.attributes['weekday'] == momentUTC(date).day()
	}

	@computed get isFiltered() {
		return this.isCollectionsFiltered && this._isTimeframeFiltered && this.belongsToParentAssignment && this._isPreferredFiltered
	}

	@computed get isVisible() {
		if (!current.isAssignmentGridRendition) return this.isFiltered
		return this._isTimeframeFiltered && this.belongsToParentAssignment
	}

	get editPolicy() {
		return current.isSupervisor || current.isCurrentAssignment(this.attributes.assignment_id)
	}

	get scheduleItem() {
		return new AvailabilityScheduleItem(this).HTML
	}

// PRIVATE

	_overlapsEffectiveRange(rangeStart, rangeEnd) {
		return (!this._effectiveEndAt || this._effectiveEndAt >= rangeStart) && this._effectiveStartAt <= rangeEnd
	}

	get _isPreferredFiltered() {
		let preferredFilter = current.filterScope('availability_preferred')
		if (preferredFilter == 'preferred') {
			return this.attributes['preferred']
		} else if (preferredFilter == 'not_preferred') {
			return !this.attributes['preferred']
		} else {
			return true
		}
	}

	get _isTimeframeFiltered() {
		let rangeStart = momentUTC(current.dateRange.padded_start_date).startOf('day')
		let rangeEnd = momentUTC(current.dateRange.padded_end_date).endOf('day')
		return this._overlapsEffectiveRange(rangeStart, rangeEnd)
	}

	get _effectiveEndAt() {
		if (this.attributes['effective_end_at'] == null) return null
		return momentUTC(this.attributes['effective_end_at']) 
	}

	get _effectiveStartAt() {
		return momentUTC(this.attributes['effective_start_at']) 
	}
}