import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['dropdownSelector']

    connect() {
        if($("."+this.dropdownSelectorTarget.value+" .dropdown #search").length == 0) {
            $(".zs-dropdown-focus").hide();
        }
    }

    zsDropdownFocus(event) {
        if($("."+this.dropdownSelectorTarget.value+" .dropdown #search").length) {
            $("."+this.dropdownSelectorTarget.value+" .dropdown a.btn").click();
            $("."+this.dropdownSelectorTarget.value+" .dropdown #search").focus();
        }
    }
}