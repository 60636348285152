import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['hiddenInput']

  clickButton(event) {
    this.value = event.currentTarget.getAttribute('data-value')
  }

  set value(value) {
    this.hiddenInputTarget.value = value 
  }
}