import { Controller } from "stimulus"
import URI from "urijs";

export default class extends Controller {
  static targets = ['notifyInput', 'notificationFields', 'assignmentIdsInput', 'notAssignedFields', 'publicInput', 'publicFields', 'quantityField', 'startTimeField', 'endTimeField', 'noteField', 'breakTimeField', 'previousAssignmentIds']

  initialize() {
    this.toggleNotify()
    this.togglePublic()
    this.refreshAssignments()
  }

  toggleNotify() {
  	if (this.hasNotifyInputTarget) {
	  	$(this.notificationFieldsTarget).toggleClass('d-none', this.notifyInputTarget.value != 'true')
  	}
  }

  selectShiftTemplate(event) {
    if (event.target.classList.contains('select-list-item-label')) {
      let startTime = this._parseTime(event.currentTarget.getAttribute('data-start-time'))
      let endTime = this._parseTime(event.currentTarget.getAttribute('data-end-time'))
      this.startTimeFieldTarget.value = startTime.format(this._inputTimeFormat)
      this.endTimeFieldTarget.value = endTime.format(this._inputTimeFormat)
      this.breakTimeFieldTarget.value = parseInt(event.currentTarget.getAttribute('data-break-time')) / 60
      this.noteFieldTarget.value = event.currentTarget.getAttribute('data-note') || ''
    }
  }

  toggleAssignment() {
    if (this.previousAssignmentIdsTarget.value.replace(/ /g, ',').split(',').includes("") != true && this._assignmentIdsValue.includes("")) {
      this._assignmentIdsValue.forEach(value => {
        if (value != "") {
          this._updateCheckbox(value, false)
        }
      })
    } else {
      this._updateCheckbox("", false)
    }
    this.previousAssignmentIdsTarget.value = this._assignmentIdsValue
    $(this.quantityFieldTarget).toggleClass('d-none', this._assignmentIdsValue.includes("") != true)
    $(this.notAssignedFieldsTarget).toggleClass('d-none', this._assignmentIdsValue.includes("") != true)
  }

  refreshAssignments() {
    $('.dropdown').on('hidden.bs.dropdown', function(){
      if (this && this.previousSibling && this.previousSibling.previousElementSibling && this.previousSibling.previousElementSibling.id == "bulk_shift_form_assignment_ids") {
        var uri = new URI(`${$('#new_bulk_shift_form_form').data('helpers--forms-refresh-path')}?${$('#new_bulk_shift_form_form').serialize()}`)
        var href = uri.removeSearch('_method').addSearch('refresh_form', true).href()
        $.get(href)
      }
    });
  }

  togglePublic() {
    if (this.publicFieldsTarget) {
      $(this.publicFieldsTarget).toggleClass('d-none', this.publicInputTarget.value == 'true')
    }
  }

// PRIVATE

  _parseTime(value) {
    return moment(value, this._timeFormat)
  }

  _updateCheckbox(value, checked) {
    this.assignmentIdsInputTargets.find(input => input.value == value).checked = checked
  }

  get _assignmentIdsValue() {
    var chk_arr_fields = this.assignmentIdsInputTargets.filter(input => input.checked)
    var chk_value_arr = []
    for(var i=0; i < chk_arr_fields.length; i++) {
      chk_value_arr.push(chk_arr_fields[i].value)
    }
    return chk_value_arr
  }

  get _inputTimeFormat() {
    return this.startTimeFieldTarget.getAttribute('type') == 'time' ? 'kk:mm' : this._timeFormat
  }

  get _timeFormat() {
    return current.addonSetting('military_time_format') ? 'H:mm' : 'h:mma'
  }
}