import PhoneTableRow from 'shared/components/phone_table_row'

export default class AssignmentPhoneTableRow extends PhoneTableRow {	
	get _avatar() {
		return this._assignmentAvatar(this.model.attributes)
	}

	get _details() {
		return [this.model.attributes.email, this.model.attributes.phone]
	}

	get _name() {
		return this._assignmentName(this.model.attributes)
	}

	get _headingLabel() {
		return this._labelFormat('role')
	}
}