import { Controller } from "stimulus"

export default class extends Controller {
    initialize() {
        var target = window.location.href.replace("assignments", "adp_wfn_get_worker")
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4) {
                if (this.status == 200){
                    document.getElementById("demo").className += " loaded success";
                    var adp_success_sync_icon_class = document.getElementById("adp_success_sync_icon").className.replace('d-none','');
                    document.getElementById("adp_success_sync_icon").className = adp_success_sync_icon_class;
                    var response = JSON.parse(this.responseText);
                    document.getElementById("demo").innerHTML += response.message;
                } else {
                    document.getElementById("demo").className += " loaded error";
                    var adp_error_sync_icon_class = document.getElementById("adp_error_sync_icon").className.replace('d-none','');
                    document.getElementById("adp_error_sync_icon").className = adp_error_sync_icon_class;
                    document.getElementById("demo").innerHTML += "Please try again later.";
                }
            }
        }
        xhttp.open("GET", target, true);
        xhttp.send();
    }
}