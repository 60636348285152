import { computed } from 'mobx'
import Model from 'shared/models/model'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import timeframeModelConcern from 'shared/models/concerns/timeframe_model_concern'
import parentAssignmentModelConcern from 'shared/models/concerns/parent_assignment_model_concern'
import conflictModelConcern from 'shared/models/concerns/conflict_model_concern'
import draggableModelConcern from 'shared/models/concerns/draggable_model_concern'
import hiddenAssignmentsModelConcern from 'shared/models/concerns/hidden_assignments_model_concern'
import TimesheetScheduleItem from 'shared/components/timesheet_schedule_item'
import TimesheetTableRow from 'shared/components/timesheet_table_row'

export default class Shift extends hiddenAssignmentsModelConcern(conflictModelConcern(draggableModelConcern(parentAssignmentModelConcern(timeframeModelConcern(filterModelConcern(Model)))))) {
	store = timesheets

	@computed get isBaseFiltered() {
		return this.isCollectionsFiltered && this.isTimeframeFiltered && this.belongsToParentAssignment
	}	

	@computed get isFiltered() {
		return this.isBaseFiltered && this._isApprovedFiltered && this._isCompleteFiltered && this._isConflictingFiltered && this._isNotesAttachedFiltered
	}

	@computed get isVisible() {
		if (current.isAssignmentGridRendition) return this.isTimeframeFiltered && this.belongsToParentAssignment
		return current.isGridOrCalendarRendition ? this.isBaseFiltered : this.isFiltered
	}

	approvedFilter(value){
		if (value == 'approved') {
			return this.attributes['approved']
		} else if (value == 'not_approved') {
			return !this.attributes['approved']
		} else {
			return true
		}
	}

	completeFilter(value){
		if (value == 'complete') {
			return this.attributes['end_at'] != null
		} else if (value == 'not_complete') {
			return this.attributes['end_at'] == null
		} else {
			return true
		}
	}

	notesAttachedFilter(value){
		if (value == 'attached') {
			return this.attributes['notes_attached']
		} else if (value == 'not_attached') {
			return !this.attributes['notes_attached']
		} else {
			return true
		}
	}

	get isDraggable() {
		return current.isManager && this.attributes.end_at != null
	}

	get scheduleItem() {
		if (this.attributes.time_off_id == null && this.attributes.show_object) {
			return new TimesheetScheduleItem(this).HTML
		}
	}

	get tableRow() {
		return new TimesheetTableRow(this).HTML
	}

// PRIVATE

	get _isApprovedFiltered() {
		return this.approvedFilter(current.filterScope('timesheet_approved'))
	}

	get _isCompleteFiltered() {
		return this.completeFilter(current.filterScope('timesheet_complete'))
	}

	get _isNotesAttachedFiltered() {
		return this.notesAttachedFilter(current.filterScope('timesheet_notes_attached'))
	}
}