import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { html, render } from 'lit-html'
import { modelWrapper } from 'shared/utilities/model_wrapper'
import { shortTimeSpan, shortTime} from 'shared/utilities/short_time'
import {compact, some, truncate} from 'lodash'

export default class extends Controller {
  initialize() {
    if (!this._visible) this.element.remove()
  }

  connect() {
    this._autorunDisposer = autorun(() => this._render())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

  _render() {
  	const HTML = this._shifts.map(shift => {
      return modelWrapper(shift, this._shiftTime(shift), {classes: {'missed': this._isShiftMissed(shift)}, tooltip: this._shiftTooltip(shift)})
    })
  	render(HTML, this.element)
  }  

  get _date() {
    if (!this.data.has('dateIndex')) return undefined
    return moment(current.dateRange.padded_start_date).add(this.data.get('dateIndex'), 'day').format('YYYY-MM-DD')
  }

  get _rowId() {
  	if (!this.data.has('rowId')) return undefined
  	return this.data.get('rowId') && parseInt(this.data.get('rowId')) || null
  }

  @computed get _shifts() {
    return shifts.cellCollectionLookup('filtered', this._rowId, this._date).filter(shift => shift.attributes.published && shift.attributes.show_object)
  }

  @computed get _timesheets() {
    return timesheets.cellCollectionLookup('filtered', this._rowId, this._date)
  }

  _isShiftMissed(shift) {
  	return !shift.inFuture && !some(this._timesheets, timesheet => timesheet.attributes.shift_id == shift.id)
  }

  _shiftTime(shift) {
    if (current.addonSetting('end_time_enabled') || current.isManager || current.isSupervisor) {
      return shortTimeSpan(shift.attributes.start_at, shift.attributes.end_at)
    } else {
      return shortTime(shift.attributes.start_at)
    }
  }

  _shiftTooltip(shift) {
    const details = compact([shift.attributes.position_name, shift.attributes.location_name, this._shift_notes(shift)])
    return details.length > 0 ? `Scheduled Shift: ${details.join(' • ')}` : 'Scheduled Shift'
  }

  get _visible() {
    if (!current.isBaseModelName('timesheet') || !current.addonSetting('timesheet_comparison_enabled')) return false
    return current.isAssignmentGridRendition || current.parentAssignment
  }

  _shift_notes(shift) {
    var value = ''
    const value_hash = shift.attributes.note
    if (current.roleSetting('private_notes')) {
      value = value_hash["all"]
    } else {
      value = value_hash["public"]
    }
    value = truncate(value, {length: 100})
    return value
  }
}