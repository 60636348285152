import { Controller } from "stimulus"
import { findController } from 'shared/utilities/controllers'
import URI from 'urijs'

export default class extends Controller {
  static targets = [ "source", "button" ]

  initialize() {
  	this._toggleActiveClass()
    this.togglePulse(false)
    this._removePolicyFailures()
    this._initialHref = this._href
    if(this.element.getAttribute('data-canny-changelog')){
      setTimeout(() => this.checkCannyChangelog(), 4000)
    }
  }
  checkCannyChangelog() {
    if($(".Canny_BadgeContainer").length){
      $(".Canny_BadgeContainer").click();
    }
  }

  ajaxBefore(event) {
    this.togglePulse(true)
  }

  ajaxComplete() {
    this.togglePulse(false)
  }

  click(event) {
    if (this._blockCommandClick(event)) return event.preventDefault()
    if (this._passesPolicy) {
      this._$element.tooltip('hide')
      if (this.data.get('remote-modal')) this._remoteModalController.prepare(this.data.get('remote-modal'))
    	if (this.data.get('disable')) this.togglePulse(true)
    }
  }

  setHref(path) {
    this._initialHref = path
    this._href = path
  }

  setParams(params = {}) {
    this.setHref(new URI(this._href).setQuery(params).href())
  }

  togglePulse(state) {
    this._$element.toggleClass('pulsing', state)
  }

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")
      const toolTipHandler = $(this.buttonTarget).tooltip({title: 'Copied!', trigger: 'manual'})
      toolTipHandler.tooltip('show')
      setTimeout(() => toolTipHandler.tooltip('hide'), 2000)
  }

// PRIVATE

  _blockCommandClick(event) {
    return this.element.getAttribute('data-remote') && event.metaKey
  }

  _removePolicyFailures() {
    if (!this._passesPolicy) {
      if (this._policyDisables) {
        this.setHref('javascript:void(0);')
        this.element.removeAttribute('data-remote')
      } else {
        this._$element.remove()        
      }
    }
  }

  _toggleActiveClass() {
  	this._$element.toggleClass('active-link', this._isActive)
  }

  get _currentPath() {
  	return window.location.pathname
  }

  get _$element() {
  	return $(this.element)
  }

  get _excludeServerParams() {
    return this.data.get('excludeServerParams')
  }

  get _href() {
  	return this._$element.attr('href')
  }

  set _href(path) {
    this.element.setAttribute('href', path)
  }

  get _isActive() {
  	return (this._href == this._currentPath) || this._regexMatch
  }

  get _method() {
    return this.element.getAttribute('data-method')
  }

  get _passesPolicy() {
    return !this.data.has('policy') || current.evaluatePolicy(this.data.get('policy'))
  }

  get _policyDisables() {
    return this.data.has('policyDisables')
  }

  get _regexMatch() {
  	if (this._currentPath.search(this.data.get('excludePattern')) >= 0) return false
    return this._currentPath.search(this.data.get('activePattern')) >= 0 
  }

  get _remoteModalController() {
		return findController('layouts--remote-modal')
  }
}