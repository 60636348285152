import { floor, round } from 'lodash'

export function durationDisplay(duration, format = 'hours', skip_hours = false) {
	if (format == 'mins') {
		return `${round(duration / 60, 0)} mins`
	} else if (current.addonSetting('duration_decimal_format')) {
		return `${round(duration / 3600, 2)} hrs`
		// if (skip_hours == true) {
		// 	return `${round(duration, 2)} hrs`
		// } else {
		// 	return `${round(duration / 3600, 2)} hrs`
		// }
	} else {
		let hours = floor(duration / 3600)
		let minutes = round((duration - (hours * 3600)) / 60)
		if (minutes == 60) {
			minutes = 0
			hours = hours + 1
		}
		return `${hours}h ${minutes}m`
	}
}

