import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { findController } from 'shared/utilities/controllers'
import { html, render } from 'lit-html'
import { repeat } from 'lit-html/directives/repeat'
import { flatten, kebabCase, without } from 'lodash'
import 'jquery-ui/ui/widgets/droppable'

export default class extends Controller {
  connect() {
  	this._autorunDisposer = autorun(() => this._render())
    this._initializeDroppable()
    this.element.classList.toggle('no-new', !this._store.newPolicy)
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

  newModel(event) {
    if (!current.dragTarget && this._store.newPolicy && event.target == this.element && current.renditionType != 'mobile_calendar') {
  		findController('layouts--remote-modal').prepare()
  		$.ajax({
				url: this._store.newPath,
				data: {[this._store.modelName]: {date: this._date, [current.rowAttribute]: this._rowId}},
				method: 'GET'
			})
  	}
  }

  showAvailableShifts() {
    findController('layouts--remote-modal').prepare()
    $.ajax({
      url: `${current.schedule.id}/shifts/available?start_date=${this._date}&end_date=${this._date}`,
      method: 'GET'
    })
  }

// PRIVATE

  _initializeDroppable() {
    if (this._explicitCollectionName) return false
    $(this.element).droppable({
      accept: `.${kebabCase(this._store.modelName)}`,
      drop: (event, ui) => {
        let model = this._store.find(ui.draggable.data('id'))
        model.dropIntoDateAndRow(this._date, this._rowId, event.shiftKey)     
      },
      tolerance: 'intersect'
    })
  }

	_render() {
		if (current.allStoresHydrated) {
			render([this._scheduleItems, this._availableShiftsButton, this._moreShiftsButton], this.element)
		}
	}

  get _availableShiftsButton() {
    if (!this._availableShifts.length) return null
    return html`
      <a data-action="renditions--schedule-item-list#showAvailableShifts" class="available-shifts-btn">
        <i class="icon-bullhorn"></i>
        Available Shifts
      </a>`
  }

    get _moreShiftsButton() {
        // if (this._mixedCollection.length > 0) {debugger;}
        if ((current.dateRange.interval != 'month') || (['time_off', 'swap'].indexOf(current.baseStore.modelName) != -1)) return null
        if (this._mixedCollection.length < 6) return null
        return html`
      <a class="more-shifts btn btn-default" href="/${current.JSONData.schedule.id}/${current.JSONData.controller_name}?interval=week&amp;start_date=${this._date}">+ ${this._mixedCollection.length - 4} ${current.JSONData.controller_name}</a>`
    }

  @computed get _availableShifts() {
    if (!current.parentAssignment || !current.isBaseModelName('shift')) return []
    return shifts.cellCollectionLookup('available', undefined, this._date)
  }

  get _collectionName() {
    return this._explicitCollectionName || 'visible'
  }
	
	get _date() {
    return moment(current.dateRange.padded_start_date).add(this.data.get('dateIndex'), 'day').format('YYYY-MM-DD') 
	}

  get _excludedStores() {
    const excludedStores = []
    if (current.renditionType == 'grid') excludedStores.push(events)
    if (current.isBaseModelName('timesheet')) excludedStores.push(shifts)
    return excludedStores
  }

  get _explicitCollectionName() {
    return this.data.has('collectionName') ? this.data.get('collectionName') : null
  }

  get _explicitStore() {
    return this.data.has('storeName') ? window[this.data.get('storeName')] : null
  }

  @computed get _mixedCollection() {
    return flatten(this._stores.map(store => {
      return store.cellCollectionLookup(this._collectionName, this._rowId, this._date)
    }))
  }

	get _rowId() {
		if (!this.data.has('rowId')) return undefined
  	return this.data.get('rowId') && parseInt(this.data.get('rowId')) || null
	}

  get _scheduleItems() {
    var mixedCollection = this._mixedCollection
    if (current.dateRange.interval == 'month' && !current.printPreview && this._mixedCollection.length > 5 && (['time_off', 'swap'].indexOf(current.baseStore.modelName) == -1)) {
        mixedCollection = mixedCollection.slice(this._mixedCollection.length - 5,this._mixedCollection.length - 1)
    }
    return repeat(mixedCollection, (m) => m.id, (m) => m.scheduleItem)
  }

	get _store() {
		return this._explicitStore || current.baseStore
	}

  @computed get _stores() {
    if (this._explicitStore) return [this._explicitStore]
    return without(current.stores, ...this._excludedStores)
  }
}