import { Controller } from "stimulus"
import { autorun, computed } from 'mobx'
import { findController } from 'shared/utilities/controllers'
import {html, render} from 'lit-html'
import { repeat } from 'lit-html/directives/repeat'

export default class extends Controller {
  static targets = ['emptyState', 'header', 'nextPageLink', 'table']

  connect() {
    this._autorunDisposer = autorun(() => this._render())
    if (!this._store.hydratedAt) {
      this._store.columns = JSON.parse(this.data.get('columns'))
      this._store.order = this.data.get('order')
      this._store.searchQuery = this.data.get('searchQuery')
      this._store.sort = this.data.get('sort')
      this._store.fetch()
    }
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

  nextPage() {
    this._store.fetch()
  }

// PRIVATE  

  _render() {
    this._toggleElements()
    const rowType = current.isPhoneVariant ? 'phoneTableRow' : 'tableRow'
    render([repeat(this._collection, (m) => m.id, (m) => m[rowType]), this._moreArchiveButton], this.tableTarget)
  }

  get _moreArchiveButton() {
    const btnText = (current.variationType == "not_archived") ?  'Archive Selected Team Members' : 'Unarchive Selected Team Members'
    return html`
      <a href="#" id="archive_assignments" value="${current.variationType}"><div id="archive" style="display:none"><span><i class="icon-archive"></i> ${btnText} </span></div></a>`
  }

  _toggleElements() {
    this.emptyStateTarget.classList.toggle('d-none', this._hasRows)
    this.headerTarget.classList.toggle('d-none', !this._hasRows)
    this._toggleNextPageLink()
  }

  _toggleNextPageLink() {
    this.nextPageLinkTarget.classList.toggle('d-none', !this._store.hasNextPage)
    this.nextPageLinkTarget.classList.toggle('pulsing', false)
    this.nextPageLinkTarget.blur()
  }

  @computed get _collection() {
    return this._store.filteredCollection
  }  

  get _hasRows() {
    return this._collection.length > 0
  }

  get _store() {
    return current.baseStore
  }
}