import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  	let duration = this.data.get('seconds')
  	setInterval(() => {
  		duration++
  		this._updateDuration(duration)
  	}, 1000)	
  }

  _updateDuration(duration) {
  	$(this.element).html(momentUTC(moment.duration(duration, 'seconds').asMilliseconds()).format("HH:mm:ss"))
  }
}