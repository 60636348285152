import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
  	current.hydrate(this._JSON)
  }

// PRIVATE

	get _JSON() {
		return JSON.parse(this.data.get('json'))
	}
}