import Assignments from 'shared/stores/assignments'
import Availabilities from 'shared/stores/availabilities'
import Current from 'shared/stores/current'
import Events from 'shared/stores/events'
import Shifts from 'shared/stores/shifts'
import Swaps from 'shared/stores/swaps'
import TimeOffRestrictions from 'shared/stores/time_off_restrictions'
import TimeOffs from 'shared/stores/time_offs'
import Timesheets from 'shared/stores/timesheets'

window.assignments = new Assignments
window.availabilities = new Availabilities
window.current = new Current
window.events = new Events
window.shifts = new Shifts
window.swaps = new Swaps
window.timeOffRestrictions = new TimeOffRestrictions
window.timeOffs = new TimeOffs
window.timesheets = new Timesheets

window.resetStores = () => {
	window.assignments.reset()
	window.availabilities.reset()
	window.events.reset()
	window.shifts.reset()
	window.swaps.reset()
	window.timeOffRestrictions.reset()
	window.timeOffs.reset()
	window.timesheets.reset()
}

$(document).on('turbolinks:before-visit', event => {
	window.current.blockFetchRequests = true
})

$(document).on('turbolinks:visit', event => {
  current.abortPendingFetchRequests()
  resetStores()
})

$(document).on('turbolinks:request-end', event => {
	window.current.blockFetchRequests = false
})