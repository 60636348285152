import TableRow from 'shared/components/table_row'

export default class SwapTableRow extends TableRow {
	columns = swaps.columns

// PRIVATE
	
	get _assignmentNameCell() {
		return this._assignmentNameWithAvatar(this.model.attributes.assignment_attributes)
	}

	get _createdAtCell() {
		return this._timestampFormat(this.model.attributes.created_at)
	}

	get _dateCell() {
		return this._allDayDateFormat
	}

	get _locationCell() {
		return this.model.attributes.location_name
	}

	get _noteCell() {
		return this.model.attributes.note
	}

	get _positionCell() {
		return this.model.attributes.position_name
	}

	get _publicCell() {
		return this._booleanFormat(this.model.attributes.public)
	}

	get _updatedAtCell() {
		return this._timestampFormat(this.model.attributes.updated_at)
	}

	get _reassignedUserNameCell() {
		if (this.model.attributes.status != 'completed') return null
		return this._assignmentNameWithAvatar(this.model.attributes.reassigned_assignment_attributes)
	}

	get _statusCell() {
		return this._labelFormat('status')
	}

	get _timeCell() {
		return this._allDayTimeFormat
	}
}