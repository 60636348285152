import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['hiddenInput', 'switch']
    static values = { dependent: String }

    initialize() {
        this.updateSwitch()
        setTimeout(() => this.enableAnimation(), 100)
        this.dependentValue = ""
        if(this.hiddenInputTarget.hasAttribute("data-dependent-field")) {
            this.dependentValue = this.hiddenInputTarget.getAttribute("data-dependent-field")
            const dependentField = this.dependentValue
            if(document.getElementById(dependentField) != null && document.getElementById(dependentField).value == "false"){
                const on = document.getElementById(dependentField).value == 'true'
                this.switchTarget.classList.toggle("on", on)
                this.switchTarget.classList.toggle("off", !on)
                var allHiddenToggle = document.getElementsByClassName("dependent-toggle-field")
                allHiddenToggle.forEach((element, index) => {
                    element.parentNode.parentNode.parentNode.classList.toggle("disable-toggle-field", !on)
                })
            }
        }
    }

    clickSwitch() {
        const dependentField = this.dependentValue
        if(document.getElementById(dependentField) != null && document.getElementById(dependentField).value == "false"){
            return
        }
        this.value = this.value == 'true' && 'false' || 'true'
        this.updateSwitch()
        this.dependentToggle()
    }

    updateSwitch() {
        const on = this.value == 'true'
        this.switchTarget.classList.toggle("on", on)
        this.switchTarget.classList.toggle("off", !on)
    }

    dependentToggle(){
        const on = this.value == 'true'
        if(this.hiddenInputTarget.hasAttribute("data-independent-field")) {
            var allHiddenToggle = document.getElementsByClassName("dependent-toggle-field")
            allHiddenToggle.forEach((element, index) => {
                element.parentNode.parentNode.parentNode.classList.toggle("disable-toggle-field", !on)
                if (this.value == 'false') {
                    element.parentNode.getElementsByClassName("toggle-switch").forEach((toggleSwitch, toggleSwitchIndex) => {
                        toggleSwitch.classList.toggle("on", on)
                        toggleSwitch.classList.toggle("off", !on)
                    })
                } else {
                    const depenentElementValue = element.value == 'true'
                    element.parentNode.getElementsByClassName("toggle-switch").forEach((toggleSwitch, toggleSwitchIndex) => {
                        toggleSwitch.classList.toggle("on", depenentElementValue)
                        toggleSwitch.classList.toggle("off", !depenentElementValue)
                    })
                }
            })
        }
    }

    enableAnimation() {
        this.switchTarget.classList.toggle('animate', true)
    }

    get value() {
        return this.hiddenInputTarget.value
    }

    set value(value) {
        this.hiddenInputTarget.value = value
        this.hiddenInputTarget.dispatchEvent(new Event('change'))
    }
}