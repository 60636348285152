import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this._showTemplate()
  }

// PRIVATE

  _showTemplate() {
    const template = this.element.getElementsByTagName('template')[0]
    const fragment = document.createRange().createContextualFragment(template.innerHTML)
    this.element.replaceChild(fragment, template)
  }
}