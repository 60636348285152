import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['menu']

  initialize() {
  	$(this.element).on('show.bs.dropdown', event => this._fetch())
  	
  	// If hide event is triggered by click within dropdown then ignore it.
  	$(this.element).on('hide.bs.dropdown', event => {
  		if (event.clickEvent && this.menuTarget.contains(event.clickEvent.target)) return false
  	})
  }

  disconnect() {
  	this.close()
  }

	show(dropdownContentHTML) {
		this._pulse(false)
  	this._setInnerHTML(dropdownContentHTML)
	}

	close() {
		this.menuTarget.classList.toggle('show', false)
	}

	// PRIVATE

	_fetch() {
		window.currentRemoteDropdownController = this
		this._pulse(true)
		this._setInnerHTML(null)
		$.get(this._path)
	}

	_pulse(state) {
		this.menuTarget.classList.toggle('pulsing', state)
	}

	_setInnerHTML(HTML) {
		$(this.menuTarget).find('.dropdown-menu-inner').html(HTML)
	}

	get _path() {
		return this.data.get('path')
	}
}

window.currentRemoteDropdownController = null

window.closeRemoteDropdown = () => {
  if (window.currentRemoteDropdownController) window.currentRemoteDropdownController.close()
}

window.showRemoteDropdown = (dropdownContentHTML) => {
  if (window.currentRemoteDropdownController) window.currentRemoteDropdownController.show(dropdownContentHTML)
}