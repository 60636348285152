import { computed } from 'mobx'
import Model from 'shared/models/model'
import filterModelConcern from 'shared/models/concerns/filter_model_concern'
import timeframeModelConcern from 'shared/models/concerns/timeframe_model_concern'
import parentAssignmentModelConcern from 'shared/models/concerns/parent_assignment_model_concern'
import conflictModelConcern from 'shared/models/concerns/conflict_model_concern'
import draggableModelConcern from 'shared/models/concerns/draggable_model_concern'
import hiddenAssignmentsModelConcern from 'shared/models/concerns/hidden_assignments_model_concern'
import ShiftScheduleItem from 'shared/components/shift_schedule_item'
import ShiftTableRow from 'shared/components/shift_table_row'

export default class Shift extends hiddenAssignmentsModelConcern(conflictModelConcern(draggableModelConcern(parentAssignmentModelConcern(timeframeModelConcern(filterModelConcern(Model)))))) {
	store = shifts

	@computed get isAvailable() {
		if (!this.attributes['published'] || !this.inFuture || !this._inParentAssignmentCollections) return false
		if (this.attributes['assignment_id'] == current.parentAssignment.id) return false
		return !this.attributes['assignment_id'] || this.attributes['status'] == 'waiting'	
	}

	@computed get isBaseFiltered() {
		return this.isCollectionsFiltered && this.isTimeframeFiltered && this.belongsToParentAssignment
	}	

	@computed get isFiltered() {
		return this.isBaseFiltered && this._isAssignedFiltered && this._isConflictingFiltered && this._isPublishedFiltered && this._isConfirmedFiltered && this._isNotesAttachedFiltered
	}

	@computed get isVisible() {
		if (current.isAssignmentGridRendition) return this.isTimeframeFiltered && this.belongsToParentAssignment
		return current.isGridOrCalendarRendition ? this.isBaseFiltered : this.isFiltered
	}

	assignedFilter(value){
		if (value == 'assigned') {
			return this.attributes['assignment_id'] != null
		} else if (value == 'not_assigned') {
			return this.attributes['assignment_id'] == null
		} else {
			return true
		}
	}

	publishedFilter(value){
		if (value == 'published') {
			return this.attributes['published']
		} else if (value == 'not_published') {
			return !this.attributes['published']
		} else {
			return true
		}
	}

	confirmedFilter(value){
		if (value == 'confirmed') {
			return this.attributes['confirmed']
		} else if (value == 'not_confirmed') {
			return !this.attributes['confirmed']
		} else {
			return true
		}
	}

	notesAttachedFilter(value){
		if (value == 'attached') {
			return this.attributes['notes_attached']
		} else if (value == 'not_attached') {
			return !this.attributes['notes_attached']
		} else {
			return true
		}
	}

	get isDraggable() {
		return current.isSupervisor
	}

	get scheduleItem() {
		if (this.attributes.show_object) return new ShiftScheduleItem(this).HTML
	}

	get tableRow() {
		return new ShiftTableRow(this).HTML
	}

// PRIVATE

	get _isAssignedFiltered() {
		return this.assignedFilter(current.filterScope('shift_assigned'))
	}

	get _isPublishedFiltered() {
		return this.publishedFilter(current.filterScope('shift_published'))
	}

	get _isConfirmedFiltered() {
		return this.confirmedFilter(current.filterScope('shift_confirmed'))
	}

	get _isNotesAttachedFiltered() {
		return this.notesAttachedFilter(current.filterScope('shift_notes_attached'))
	}

	get _inParentAssignmentCollections() {
		let hasPosition = !this.attributes['position_id'] || this.isCollectionFiltered('position_id', current.parentAssignment.position_ids)
		let hasLocation = !this.attributes['location_id'] || this.isCollectionFiltered('location_id', current.parentAssignment.location_ids)
		return hasPosition && hasLocation
	}
}