import { html } from 'lit-html'
import { modelWrapper } from 'shared/utilities/model_wrapper'
import { compact, kebabCase, pickBy, truncate } from 'lodash'
import { shortTimeSpan, shortTime } from 'shared/utilities/short_time'
import {durationDisplay} from "shared/utilities/duration";

export default class ScheduleItem {
	model = null

	constructor(model) {
		this.model = model
	}

	get HTML() {
		const classes = Object.assign((this._classes || {}), {'filtered': this.model.isFiltered, 'schedule-item': true})
		if(current.renditionType == 'mobile_calendar') return modelWrapper(this.model, this._innerHTML, {classes: classes, styles: this.model.dayStyles})
		return modelWrapper(this.model, this._innerHTML, {classes: classes, styles: this.model.dayStyles, html: "true", tooltip: this._attributes['tooltip']})
	}

// PRIVATE

	_assignmentName(assignmentAttributes) {
		if (assignmentAttributes.user_id) {
			return `${assignmentAttributes.first_name} ${assignmentAttributes.last_name}`
		} else {
			return `Open Shift`
		}
	}

	_detailNote(attribute = 'note') {
		var value = ''
		if (["shift", "timesheet"].includes(this.model.store.modelName)) {
			const value_hash = this._attributes[attribute]
			if (this._allowedPrivateNotes) {
				value = value_hash["all"]
			} else {
				value = value_hash["public"]
			}
			value = truncate(value, {length: 100})
		} else {
			value = truncate(this._attributes[attribute], {length: 100})
		}
		if (!value || !this._showInlineNote) return null
		return value
	}

	_indicatorIcon(icon, tooltip) {
		return html`<i class="icon-${icon}" data-tooltip="${tooltip}"></i>`
	}

	_indicatorNote(attribute = 'note') {
		if (["shift", "timesheet"].includes(this.model.store.modelName)) {
			const value_hash = this._attributes[attribute]
			var value = ''
			if (this._allowedPrivateNotes) {
				value = value_hash["all"]
			} else {
				value = value_hash["public"]
			}
			if (!value || this._showInlineNote) return null
			return this._indicatorIcon('info-circle', value)
		} else {
			const value = this._attributes[attribute]
			if (!value || this._showInlineNote) return null
			return this._indicatorIcon('info-circle', value)
		}
	}

	_statusIcon(attribute) {
		const text = this._attributes[`${attribute}_label`]
		return html`<div class="status ${kebabCase(this._attributes[`${attribute}`])}" data-tooltip="${text}">${text[0]}</div>`
	}

	get _attributes() {
		return this.model.attributes
	}

	get _colorBorderHTML() {
		return html`<i class="color-border" style="background:#${this._attributes.position_color}"></i>`
	}

	get _detailsHTML() {
		const compactDetails = compact(this._details)
		if (compactDetails.length == 0) return null
		return html`<div class="details">${compactDetails.join(' • ')}</div>`
	}

	get _headingHTML() {
		return html`
			<div class="heading">
				${this._headingIcon ? html`<i class="icon-${this._headingIcon}"></i>` : null}
				${this._titleHTML}
				${this._indicatorsHTML}
			</div>`
	}

	get _indicatorsHTML() {
		const compactIndicators = compact(this._indicators)
		if (compactIndicators.length == 0) return null
		return html`<div class="indicators">${compactIndicators}</div>`
	}

	get _showInlineNote() {
		return current.addonSetting('inline_note_enabled') || current.isTabletOrPhoneVariant
	}

	get _allowedPrivateNotes(){
		return current.roleSetting('private_notes')
	}

	get _time() {
		if (this.model.store.hasAllDayAttribute && this._attributes.all_day) return 'All Day'
		if (this.model.store.modelName != 'shift') return shortTimeSpan(this._attributes.start_at, this._attributes.end_at)
		if (current.addonSetting('end_time_enabled') || current.isManager || current.isSupervisor) {
			return shortTimeSpan(this._attributes.start_at, this._attributes.end_at)
		} else {
			return shortTime(this._attributes.start_at)
		}
	}

	get _time_in_org_tz() {
		if (this.model.store.hasAllDayAttribute && this._attributes.all_day || this._attributes.start_at_org_tz == "") return ''
		if (this.model.store.modelName != 'shift') return "(" + shortTimeSpan(this._attributes.start_at_org_tz, this._attributes.end_at_org_tz) + ")"
		if (current.addonSetting('end_time_enabled') || current.isManager || current.isSupervisor) {
			return "(" + shortTimeSpan(this._attributes.start_at_org_tz, this._attributes.end_at_org_tz) + ")"
		} else {
			return "(" + shortTime(this._attributes.start_at_org_tz) + ")"
		}
	}

	get _titleHTML() {
		const time_zone_models = ['shift', 'timesheet', 'swap', 'time_off']
		if (current.addonSetting('end_time_enabled') && time_zone_models.includes(this.model.store.modelName)) {
			return html`<div class="title">${this._time} <span class="time-in-org-tz">${this._time_in_org_tz}</span></div>`
		} else {
			return html`<div class="title">${this._time}</div>`
		}
	}

	get _breakHTML() {
		if(this._attributes.break_duration == 0 || this._attributes.break_duration == null){
			return html``
		}
		const break_duration = durationDisplay(this._attributes.break_duration, 'mins')
		return html`
			<div class="break-details">
				Break - ${break_duration}
			</div>`
	}
}