import { Controller } from "stimulus"
import { max, round } from "lodash"

export default class extends Controller {
  static targets = ['cardError', 'cardInput', 'cardInputWrapper', 'cardWrapper', 'discountTotal', 'intervalInput', 'intervalWrapper', 'monthDescriptor', 'monthIntervalTotal', 'nextStepButton',  'planKeyInput', 'planStepButton', 'previousStepButton', 'quantityInput', 'quantityWrapper', 'step', 'stripeCardTokenInput', 'submitButton', 'subtotal', 'total', 'useDefaultSourceInput', 'yearDescriptor', 'yearIntervalTotal']

  connect() {
    this._toggleStep()
    this._initializeStripeCardElement()
    this._updateTotals()
    this._toggleIntervalDescriptor()
    this.toggleUseDefaultSource()
  }

  decreaseQuantity() {
    this._quantity -= 1
  }

  increaseQuantity() {
    this._quantity += 1
  }

  selectInterval() {
    this._updateTotals()
    this._toggleIntervalDescriptor()
  }

  selectPlanKey() {
    this._updateTotals()
  }

  nextStep() {
    this._currentStep += 1
    this._toggleStep()
  }
  
  previousStep() {
    this._currentStep -= 1
    this._toggleStep()
  }

  submit() {
    if (this._useDefaultSource || this._isFree) {
      this._toggleSubmitPulse(true)
      railsUJS.fire(this.element, 'submit')
    } else {
      this._toggleSubmitPulse(true)
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          this._toggleSubmitPulse(false)
          this.cardErrorsTarget.textContent = result.error.message
        } else {
          this.stripeCardTokenInputTarget.value = result.token.id
          railsUJS.fire(this.element, 'submit')
        }
      })
    }
  }

  toggleUseDefaultSource() {
    this.cardInputWrapperTarget.classList.toggle('d-none', this._useDefaultSource)
  }

  updateQuantity() {
    if ((this._quantity || 0) < this._assignmentCount) this._quantity = this._assignmentCount
    this._updateTotals()
  }

  // PRIVATE

  get _amountOff() {
    return parseInt(this.data.get('amountOff') || 0)
  }

  get _assignmentCount() {
    return parseInt(this.data.get('assignmentCount'))
  }

  get _currentStep() {
    return parseInt(this.data.get('currentStep'))
  }

  set _currentStep(value) {
    return this.data.set('currentStep', value)
  }

  get _discountTotal() {
    if (this._percentOff > 0) return this._subtotal * (this._percentOff / 100)
    if (this._amountOff > 0) return (this._amountOff / 100)
    return 0
  }

  get _interval() {
    return this.hasIntervalInputTarget ? this._selectedIntervalInput.value : 'month'
  }

  get _isFree() {
    return this._total <= 0
  }

  get _percentOff() {
    return parseInt(this.data.get('percentOff') || 0)
  }

  get _quantity() {
    return parseInt(this.quantityInputTarget.value)
  }

  set _quantity(value) {
    this.quantityInputTarget.value = value
    return this.updateQuantity()
  }

  get _selectedIntervalInput() {
    return this.intervalInputTargets.find(input => input.checked)
  }

  get _selectedPlanKeyInput() {
    return this.planKeyInputTargets.find(input => input.checked)
  }

  get _selectedUseDefaultSourceInput() {
    return this.useDefaultSourceInputTargets.find(input => input.checked)
  }

  get _stripePublishableKey() {
   return this.data.get('stripePublishableKey')
  }

  get _subtotal() {
    return this._intervalTotal(this._interval)
  }

  get _total() {
    return max([0, this._subtotal - this._discountTotal])
  }

  get _useDefaultSource() {
    return this.hasUseDefaultSourceInputTarget && this._selectedUseDefaultSourceInput.value == 'true'
  }

  _formatCurrency(number) {
    return number.toFixed(2).toLocaleString()
  }

  _initializeStripeCardElement() {
    this.stripe = Stripe(this._stripePublishableKey)
    this.card = this.stripe.elements().create('card', {
      style: {base: {fontFamily: '"Proxima Nova W01", "Helvetica Neue", Helvetica, Arial, sans-serif', color: '#333c48', '::placeholder': {color: '#b8c0ca'}}},
      invalid: {color: '#ef2135'}
    })
    this.card.mount(this.cardInputTarget)
    this.card.addEventListener('change', ({error}) => { this.cardErrorTarget.textContent = error && error.message || '' })
  }

  _intervalCost(interval) {
    return parseInt(this._selectedPlanKeyInput.dataset[`${interval}Cost`] || 0)
  }

  _intervalTotal(interval) {
    return round(this._quantity * this._intervalCost(interval) / 100, 2)
  }

  _toggleFreeSteps() {
    if (this.hasIntervalWrapperTarget) this.intervalWrapperTarget.classList.toggle('d-none', this._isFree)
    this.cardWrapperTarget.classList.toggle('d-none', this._isFree)
    this.quantityWrapperTarget.classList.toggle('d-none', this._isFree)
  }

  _toggleIntervalDescriptor() {
    this.monthDescriptorTarget.classList.toggle('d-none', this._interval != 'month')
    this.yearDescriptorTarget.classList.toggle('d-none', this._interval != 'year')
  }

  _toggleSubmitPulse(state) {
    this.submitButtonTarget.classList.toggle('pulsing', state)
  }

  _toggleStep(key) {
    this.stepTargets.forEach(step => {
      step.classList.toggle('d-none', this._currentStep != step.getAttribute('data-step'))
    })
    this.previousStepButtonTarget.classList.toggle('d-none', this._currentStep == 1)
    this.nextStepButtonTarget.classList.toggle('d-none', this._currentStep == 3)
    this.submitButtonTarget.classList.toggle('d-none', this._currentStep != 3)
  }

  _updateTotals() {
    this.subtotalTarget.innerHTML = `$${this._formatCurrency(this._subtotal)}`
    this.discountTotalTarget.innerHTML = `$${this._formatCurrency(this._discountTotal)}`
    this.totalTarget.innerHTML = `$${this._formatCurrency(this._total)}`
    if (this.hasIntervalInputTarget) {
      this.monthIntervalTotalTarget.innerHTML = `$${this._formatCurrency(this._intervalTotal('month'))}`
      this.yearIntervalTotalTarget.innerHTML = `$${this._formatCurrency(round(this._intervalTotal('year') / 12, 2))}`
    }
    this._toggleFreeSteps()
  }
}