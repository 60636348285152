import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'submitButton']

  initialize() {
  	this._resize()
  	this._toggleSubmitButton()
  }

  input(event) {
  	this._resize()
  	this._toggleSubmitButton()	  	
  	this._removeFieldErrors()	  	
  }

  keypress(event) {
  	if (event.which == 13 && !event.shiftKey) {
  		event.preventDefault()
  		event.stopPropagation()
  		railsUJS.fire(this.element.closest('form'), 'submit')
  	}
  }

  _removeFieldErrors() {
  	$(this.inputTarget).parent().toggleClass('field_with_errors', false)
  }

  _resize() {
  	this.inputTarget.style.height = 'auto'
  	this.inputTarget.style.height = (this.inputTarget.scrollHeight) + 'px'
  }

  _toggleSubmitButton() {
    if (this.hasSubmitButtonTarget) {
    	$(this.submitButtonTarget).toggleClass('disabled', !this.inputTarget.value)
    }
  }
}