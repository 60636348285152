import ScheduleItem from 'shared/components/schedule_item'
import { html } from 'lit-html'
import { durationDisplay } from 'shared/utilities/duration'

export default class SwapScheduleItem extends ScheduleItem {
	get _classes() {
		return {
			'shift': true,
			'has-swap': true,
			'not-published': !this._attributes.published
		}
	}

	get _details() {
		const result = []
		if (current.rowType != 'assignments' && !current.parentAssignment) result.push(this._assignmentName(this._attributes.assignment_attributes))
		if (current.rowType != 'positions') result.push(this._attributes.position_name)
		if (current.rowType != 'locations') result.push(this._attributes.location_name)
		result.push(this._detailNote('shift_note'))
		return result
	}

	get _indicators() {
		const result = []
		if (this._attributes.conflict) result.push(this._indicatorIcon('exclamation-circle', 'Error'))
		if (this._attributes.break_time > 0) result.push(this._indicatorIcon('pause-circle', durationDisplay(this._attributes.break_time)))
		result.push(this._indicatorNote('shift_note'))
		result.push(this._statusIcon('status'))
		return result
	}

	get _innerHTML() {
		return html`
			${this._colorBorderHTML}
			${this._headingHTML}
			${this._detailsHTML}
		`
	}
}