import { Controller } from "stimulus"
import { kebabCase } from 'lodash'

export default class extends Controller {
  toggle(event) {
  	const input = event.currentTarget
  	const cssClass = kebabCase(input.name.replace('print_preview_options', ''))
  	$('body').toggleClass(cssClass, input.value == 'true')
  }

  scale(event) {
  	const scaleValue = event.currentTarget.value
  	$('.page-body').css('font-size', `${scaleValue}%`)
  }
}