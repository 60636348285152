import ScheduleItem from 'shared/components/schedule_item'
import { html } from 'lit-html'

export default class AvailabilityScheduleItem extends ScheduleItem {
	get _classes() {
		return {
			'preferred': this._attributes.preferred
		}
	}

	get _details() {
		const result = []
		if (current.rowType != 'assignments' && !current.parentAssignment) result.push(this._assignmentName(this._attributes.assignment_attributes))
		result.push(this._detailNote())
		return result
	}

	get _headingIcon() {
		return this._attributes.preferred ? 'thumbs-up' : 'thumbs-down'
	}

	get _indicators() {
		return [this._indicatorNote()]
	}

	get _innerHTML() {
		return html`
			${this._headingHTML}
			${this._detailsHTML}
		`
	}
}