import Store from 'shared/stores/store'
import Timesheet from 'shared/models/timesheet'
import cellCollectionLookupStoreConcern from 'shared/stores/concerns/cell_collection_lookup_store_concern'
import refetchStoreConcern from 'shared/stores/concerns/refetch_store_concern'
import hiddenAssignmentsStoreConcern from 'shared/stores/concerns/hidden_assignments_store_concern'

export default class Timesheets extends hiddenAssignmentsStoreConcern(refetchStoreConcern(cellCollectionLookupStoreConcern(Store))) {
  defaultOrder = 'timeframe'
  modelClass = Timesheet
  modelName = 'timesheet'
  refetchTrackedStoreNames = ['shifts', 'timesheets']

  get newPolicy() {
        return current.roleSetting('edit_timesheet') || current.addonSetting('timesheet_editable')
	}
}