import { Controller } from "stimulus"
import { findController } from 'shared/utilities/controllers'
import URI from 'urijs'

export default class extends Controller {
  static targets = ['closeButton', 'modalContent']

  initialize() {
    this._$element.on('hidden.bs.modal', event => {
      this._$modalContent.html(null)
    })
  }

  connect() {
    this._preload()
  }

  disconnect() {
    this.close()
    this.data.set('preloadDisabled', true)
  }

  close() {
  	this._$element.modal('hide')
  }

  show(modalContentHTML) {
  	this._$closeButton.toggleClass('d-none', false)
    this._$modalContent.toggleClass('pulsing', false)
  	this._$modalContent.html(modalContentHTML)
    this._showModal()
  }

  prepare(modalStyle = null) {
  	if (this._flashMessageController) this._flashMessageController.removeAllMessages()
    this._$modalContent.html(null)
  	this._$modalContent.toggleClass('pulsing', true)
  	this._$closeButton.toggleClass('d-none', true)
  	this._setModalStyle(modalStyle)
    this._showModal()	
  }

  _preload() {
    if (!this._preloadDisabled && this._preloadOptions.path) {
      this.prepare(this._preloadOptions.style)
      $.get(this._preloadOptions.path)
    }
  }

  _setModalStyle(modalStyle) {
  	this._$modalDialog.attr('class', 'modal-dialog')
  	if (modalStyle) this._$modalDialog.toggleClass(modalStyle, true)
  }

  _showModal() {
    $('.dropdown-menu.show').toggleClass('show', false)
    this._$element.modal('show')
  }

  get _preloadDisabled() {
    return this.data.get('preloadDisabled') == 'true'
  }

  get _$closeButton() {
  	return $(this.closeButtonTarget)
  }

  get _$element() {
  	return $(this.element)
  }

  get _$modalContent() {
  	return $(this.modalContentTarget)
  }

  get _$modalDialog() {
  	return this._$element.find('.modal-dialog')
  }

  get _flashMessageController() {
  	return findController('layouts--flash-messages')
  }

  get _preloadOptions() {
    return JSON.parse(this.data.get('preloadOptions'))
  }
}

window.showRemoteModal = (modalContentHTML) => {
  findController('layouts--remote-modal').show(modalContentHTML)
}

window.closeRemoteModal = (modalContentHTML) => {
  findController('layouts--remote-modal').close()
}