import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['publicInput', 'publicFields']

  initialize() {
    this.togglePublic()
  }

  togglePublic() {
  	$(this.publicFieldsTarget).toggleClass('d-none', this.publicInputTarget.value == 'true')
  }
}