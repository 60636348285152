import { Controller } from "stimulus"
import "timepicker"
import { throttle } from 'lodash'

export default class extends Controller {
  initialize() {
  	const isMilitaryTime = current.addonSetting('military_time_format')
  	const momentFormat = isMilitaryTime ? 'H:mm' : 'h:mma'
  	const pickerFormat = isMilitaryTime ? 'G:i' : 'g:ia'
  	this._$element.timepicker({timeFormat: pickerFormat, show2400: isMilitaryTime, 'maxTime': '11:30pm'})
		const value = this.element.value
		const time = moment(value, momentFormat).isValid() && moment(value, momentFormat).toDate() || null
		this._$element.timepicker('setTime', time)

		// Trigger our own timeChanged event so we can control change events more precisely
		this._$element.on('changeTime', event => {
			this.element.dispatchEvent(new Event('timeChanged'))
		})
  }

  get _$element() {
  	return $(this.element)
  }
}