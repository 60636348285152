$(document).on('turbolinks:load', event => { 
	$('.page-container').scroll(event => {
		let mainContainer = $(event.target)
		mainContainer.toggleClass('horizontally-scrolled', $(event.target).scrollLeft() > 0)
	})

	$('.page-body-wrapper').scroll(event => {
		let mainContainer = $('.page-container')
		mainContainer.toggleClass('horizontally-scrolled', $(event.target).scrollLeft() > 0)
	})
})