import { Controller } from "stimulus"
import { autorun } from 'mobx'
import { identity, pickBy } from 'lodash'

export default class extends Controller {
  static targets = ['indicator']

  connect() {
    this._autorunDisposer = autorun(() => this._toggleIndicator())
  }

  disconnect() {
    if (this._autorunDisposer) this._autorunDisposer()
  }

// PRIVATE

  _toggleIndicator() {
  	this.indicatorTarget.classList.toggle('d-none', !this._hasFilters)
  }

  get _hasFilters() {
  	return this._hasFilterCollections || this._hasFilterScopes
  }

  get _hasFilterCollections() {
  	return (current.filterAssignmentIds.length + current.filterLocationIds.length + current.filterPositionIds.length) > 0
  }

  get _hasFilterScopes() {
  	return Object.keys(pickBy(current.filter.scopes, identity)).length > 0
  }
}