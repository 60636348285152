import TableRow from 'shared/components/table_row'

export default class AssignmentTableRow extends TableRow {
	columns = assignments.columns

// PRIVATE

	get _emailCell() {
		if(this.model.attributes.email === ""){
			return "-"
		} else {
			return this.model.attributes.email
		}
	}

	get _lastSessionAtCell() {
		if(this.model.attributes.last_session_at === null) {
			return "-"
		} else {
			return this._timestampFormat(this.model.attributes.last_session_at)
		}
	}

	get _nameCell() {
		return this._assignmentNameWithAvatar(this.model.attributes)
	}

	get _phoneCell() {
		if(this.model.attributes.phone === ""){
			return "-"
		} else {
			return this.model.attributes.phone
		}
	}

	get _roleCell() {
		return this._labelFormat('role')
	}

	get _statusCell() {
		return this._labelFormat('status')
	}
}