import { html } from 'lit-html'
import { modelWrapper } from 'shared/utilities/model_wrapper'
import { camelCase, kebabCase, round } from 'lodash'
import { shortTimeSpan, shortTime } from 'shared/utilities/short_time'
import { durationDisplay } from 'shared/utilities/duration'

export default class TableRow {
	columns = []
	model = null

	constructor(model) {
		this.model = model
	}

	get HTML() {
		const content = this.columns.map(cell => this._cellWrapper(cell, this[`_${camelCase(cell.key)}Cell`]))
		return modelWrapper(this.model, content, {classes: {'table-row': true}})
	}

// PRIVATE

	_assignmentAvatar(assignmentAttributes) {
		if (!assignmentAttributes.user_id) return html`<span class="avatar no-assignment"></span>`
		return html`<span class="avatar color-${this._avatarColor(assignmentAttributes)}">${assignmentAttributes.first_name[0]}${assignmentAttributes.last_name[0]}</span>`
	}

	_assignmentName(assignmentAttributes) {
		if (!assignmentAttributes.user_id) return 'Open Shift'
		return html`${assignmentAttributes.first_name} ${assignmentAttributes.last_name} ${this._assignmentStatusIcon(assignmentAttributes)}`
	}

	_assignmentNameWithAvatar(assignmentAttributes) {
		return html`
			${this._assignmentAvatar(assignmentAttributes)}
			${this._assignmentName(assignmentAttributes)}
		`
	}

	_assignmentStatusIcon(assignmentAttributes) {
		if (assignmentAttributes.archived) return html`<i class="icon-archive" data-tooltip="Archived"></i>`
		if (!assignmentAttributes.on_schedule) return html`<i class="icon-eye-slash" data-tooltip="Not On Schedule"></i>`
		return null
	}

	_avatarColor(assignmentAttributes) {
		const base = parseInt((assignmentAttributes.user_id).toString().slice(-1))
		return base < 5 ? base : base - 5
	}

	_booleanFormat(value) {
		if (!value) return null
		return html`<i class="icon-check-circle text-green"></a>`
	}

	_cellWrapper(cell, content) {
		if (this.model.store.modelName == 'assignment') return html`<div class="cell assignment-cell ${cell.class}">${content}</div>`
		if (cell.key == "time") return html`<div class="cell ${cell.class}" data-html="true" data-tooltip="${this.model.attributes['tooltip']}">${content}</div>`
		return html`<div class="cell ${cell.class}">${content}</div>`
	}

	_dateFormat(datetime) {
		return momentUTC(datetime).format('MMM D, YYYY')
	}

	_dateTimeFormat(startAt, endAt) {
		return `${this._dateFormat(startAt)} from ${this._timeFormat(startAt, endAt)}`
	}

	_durationFormat(duration, format = 'hours') {
		if (this.model.store.modelName != 'shift') return durationDisplay(duration, format)
		if (current.addonSetting('end_time_enabled') || current.isManager || current.isSupervisor) {
			return durationDisplay(duration, format)
		}
	}

	_labelFormat(attribute) {
		return html`
			<span class="label label-${kebabCase(this.model.attributes[`${attribute}`])}">
				${this.model.attributes[`${attribute}_label`]}
			</span>
		`
	}

	_timeFormat(startAt, endAt) {
		if (this.model.store.modelName != 'shift') return shortTimeSpan(startAt, endAt)
		if (current.addonSetting('end_time_enabled') || current.isManager || current.isSupervisor) {
			return shortTimeSpan(startAt, endAt)
		} else {
			return shortTime(startAt)
		}
	}

	_timestampFormat(datetime) {
		return html`
			<time datetime="${datetime}" data-local="time-ago"></time>
		`
	}

	_warningFormat(value) {
		if (!value) return null
		return html`<i class="icon-exclamation-circle text-orange"></a>`
	}

	get _allDayDateFormat() {
		if (!this.model.attributes.all_day) return this._dateFormat(this.model.attributes.start_at)
		return `${momentUTC(this.model.attributes.start_at).format('MMM D')} - ${momentUTC(this.model.attributes.end_at).format('MMM D, YYYY')}`
	}

	get _allDayDateTimeFormat() {
		if (this.model.attributes.all_day) return this._allDayDateFormat
		return `${this._allDayDateFormat} from ${this._allDayTimeFormat}`
	}

	get _allDayTimeFormat() {
		if (this.model.attributes.all_day) return 'All Day'
		return this._timeFormat(this.model.attributes.start_at, this.model.attributes.end_at)
	}
	
	get _conflictFormat() {
		if (!this.model.attributes.conflict) return null
		return html`<i class="icon-exclamation-circle text-red"></a>`
	}
}