import { Controller } from "stimulus"

export default class extends Controller {
  pollingCanceled = false
  pollInterval = 3000

  connect() {
  	setTimeout(() => this.poll(), this.pollInterval)
  }

  disconnect() {
  	this.pollingCanceled = true
  }

  poll() {
  	if (this._path && !this.pollingCanceled) $.get(this._path)
  }

// PRIVATE 

	get _path() {
		return this.data.get('path')
	}
}