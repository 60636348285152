import { action } from 'mobx'

const draggableModelConcern = (superclass) => class extends superclass {  
	@action dropIntoDateAndRow(date, rowId = null, duplicate = false) {
		const model = duplicate ? this.clone : this
		model.setDate(date)
		if (current.renditionType == 'grid') {
			model.attributes[current.rowAttribute] = rowId
			model.save({date: date, [current.rowAttribute]: rowId})	
		} 
		else {
			model.save({date: date})
		}
	}
}

export default draggableModelConcern