import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['assignmentIdInput', 'notAssignedFields', 'publicInput', 'publicFields', 'quantityField', 'startTimeField', 'endTimeField', 'noteField', 'breakTimeField']

  initialize() {
    this.togglePublic()
  }

  selectShiftTemplate(event) {
    if (event.target.classList.contains('select-list-item-label')) {
      let startTime = this._parseTime(event.currentTarget.getAttribute('data-start-time'))
      let endTime = this._parseTime(event.currentTarget.getAttribute('data-end-time'))
      this.startTimeFieldTarget.value = startTime.format(this._inputTimeFormat)
      this.endTimeFieldTarget.value = endTime.format(this._inputTimeFormat)
      this.breakTimeFieldTarget.value = parseInt(event.currentTarget.getAttribute('data-break-time')) / 60
      this.noteFieldTarget.value = event.currentTarget.getAttribute('data-note') || ''
    }
  }

  toggleAssignment() {
    $(this.quantityFieldTarget).toggleClass('d-none', this._assignmentIdValue != '') 
    $(this.notAssignedFieldsTarget).toggleClass('d-none', this._assignmentIdValue != '') 
  }

  togglePublic() {
    $(this.publicFieldsTarget).toggleClass('d-none', this.publicInputTarget.value == 'true')
  }

// PRIVATE

  _parseTime(value) {
    return moment(value, this._timeFormat)
  }

  get _assignmentIdValue() {
    return this.assignmentIdInputTargets.find(input => input.checked).value
  }

  get _inputTimeFormat() {
    return this.startTimeFieldTarget.getAttribute('type') == 'time' ? 'kk:mm' : this._timeFormat
  }

  get _timeFormat() {
    return current.addonSetting('military_time_format') ? 'H:mm' : 'h:mma'
  }
}