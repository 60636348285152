const statusModelConcern = (superclass) => class extends superclass {  
	statusFilter(value){
		if (value) {
			return this.attributes['status'] == value
		} else {
			return true
		}
	}

	get _isStatusFiltered() {
		return this.statusFilter(current.filterScope(`${this.store.modelName}_status`))
	}
}

export default statusModelConcern